import React from 'react'
import { useCart } from '../../hooks/useCart.js'
import { formatPrice } from '../../utils/functions.js'
import { TrashIcon } from '@heroicons/react/outline'

export const CartCard = ({ item, isOrderHistory }) => {
  const { removeFromCart } = useCart()
  return (
    <div className="w-full">
      <div className="flex">
        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
        <img
          className="lg:hidden w-1/3 h-20 w-20 my-auto"
          src={item.image_url}
          alt={`Image of the ${item.product_name}`}
        />
        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
        <img
          className="hidden lg:block w-1/3"
          src={item.image_url}
          alt={`Image of the ${item.product_name}`}
        />
        <div className="px-4 pt-4 w-2/3 flex flex-col justify-between">
          <div className="flex flex-col space-y-1">
            <span className="text-primary-default text-lg">
              {item.product_name}
            </span>
            <span className="text-accent-mid font-bold text-xl">
              {formatPrice(item.product_price)}
            </span>
          </div>
          {!isOrderHistory ? (
            <button
              onClick={() => removeFromCart(item)}
              className="p-1 flex justify-center items-center hover:text-white hover:bg-secondary-default bg-secondary-light text-secondary-default"
            >
              <TrashIcon className="block w-8 h-8" />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  )
}
