const GRAPH_LOCAL_STORAGE_KEY = 'graph_data'

export function getStoredGraphData() {
  const storedGraphData = localStorage.getItem(GRAPH_LOCAL_STORAGE_KEY)
  return storedGraphData ? JSON.parse(storedGraphData) : null
}

export function setStoredGraphData(graphData) {
  localStorage.setItem(GRAPH_LOCAL_STORAGE_KEY, JSON.stringify(graphData))
}

export function clearGraphData() {
  localStorage.removeItem(GRAPH_LOCAL_STORAGE_KEY)
}