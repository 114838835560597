import React from 'react'

export const Heading = ({ children }) => (
  <h1 className="text-primary-default text-xl">{children}</h1>
)

export const SubHeading = ({ children }) => (
  <h1 className="text-tertiary-default text-lg">{children}</h1>
)

export const Paragraph = ({ children }) => (
  <p className="text-gray-700">{children}</p>
)
