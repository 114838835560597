import React from 'react'

// Components
import { Heading, Paragraph, SubHeading } from './CopyComponents'

const week1Areas = ['Roodeport', 'Sandton', 'Rosebank', 'Midrand', 'Eastgate']
const week2Areas = ['Soweto', 'Sandton', 'Rosebank', 'Midrand', 'Eastgate']
const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday']

const Table = ({ days, areas }) => (
  <div className="flex flex-col my-4">
    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div className="shadow overflow-hidden border-b border-gray-200">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {days.map(day => (
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    key={day}
                  >
                    {day}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                {areas.map(area => (
                  <td
                    key={area}
                    className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"
                  >
                    {area}
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
)

export const CarWashCopy = () => {
  return (
    <div className="space-y-6 my-4">
      <Heading>
        <strong>Car Wash Service</strong>
      </Heading>
      <div>
        <Heading>Service Description</Heading>
        <Paragraph>
          This service can be rendered at any parking lot on the Hollard
          premises. <br />
          <br /> Each car takes approximately 35 minutes to wash.
        </Paragraph>
      </div>
      <div>
        <Heading>Payment</Heading>
        <Paragraph>
          You can pay using the following methods: <br />
          <ul className="list-disc list-inside">
            {/*<li>Debit Card</li>*/}
            <li>Kiosk payment</li>
            <li>
              Payroll:{' '}
              <a
                className="text-tertiary-default hover:text-tertiary-mid"
                href="https://qulmdakymnldvnbspzef.supabase.in/storage/v1/object/public/files/BALOYI CAR WAS1_docx 2.doc"
                target="_blank"
                rel="noreferrer noopener"
              >
                Download the form
              </a>{' '}
              and submit it to Baloyi for signature and then submit the form to
              HR
            </li>
          </ul>
        </Paragraph>
      </div>
      <div>
        <Heading>Key Collection</Heading>
        <Paragraph>
          Your car keys will be collected and returned by someone on the car
          wash team.
        </Paragraph>
      </div>
      {/*<div>*/}
      {/*  <Heading>Operating Areas</Heading>*/}
      {/*  <SubHeading>Week 1 and 3 of the month</SubHeading>*/}
      {/*  <Table areas={week1Areas} days={days} />*/}
      {/*</div>*/}
      {/*<div>*/}
      {/*  <SubHeading>Week 2 and 4 of the month</SubHeading>*/}
      {/*  <Table areas={week2Areas} days={days} />*/}
      {/*</div>*/}
      <Heading>Make a booking</Heading>
    </div>
  )
}
