import { useQuery, useQueryClient } from 'react-query'
import { QUERY_KEYS } from '../config/constants'

import { Axios } from '../config/axios'

const getServiceProviders = async () => {
  const { data } = await Axios({
    method: 'GET',
    url: '/ServiceProviders',
  })

  return data
}

export const useServiceProviders = () => {
  const { data: serviceProviders, isFetching: isFetchingServiceProviders } =
    useQuery(QUERY_KEYS.SERVICE_PROVIDERS, getServiceProviders)

  return {
    serviceProviders,
    isFetchingServiceProviders,
  }
}

export const usePrefetchServiceProviders = () => {
  const queryClient = useQueryClient()

  queryClient.prefetchQuery(QUERY_KEYS.SERVICE_PROVIDERS, getServiceProviders)
}