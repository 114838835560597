import React from 'react'

// Components
import { Heading, Paragraph } from '../shared/CopyComponents'

export const SeamstressCopy = () => {
  return (
    <div className="space-y-6 my-4">
      <Heading>
        <strong>Tailor Service</strong>
      </Heading>
      <div>
        <Heading>Drop-offs / Measurements</Heading>
        <Paragraph>
          Drop offs and measurements are done on Tuesdays and Fridays. Select a
          date and time below to make a booking.
        </Paragraph>
      </div>
      <div>
        <Heading>Collections</Heading>
        <Paragraph>
          Garments are collected on Tuesdays and Friday by appointment. Once
          garments have been worked on and completed, you will be notified on
          this application and via email. You can then set up a date and time to
          collect your clothing.
        </Paragraph>
      </div>
      <div>
        <Heading>Payment</Heading>
        <Paragraph>
          Payment is made at the Kiosk at the Parktown campus
        </Paragraph>
      </div>
      <Heading>Make a booking</Heading>
    </div>
  )
}
