import React from 'react'

// Components
import { Heading, Paragraph } from './CopyComponents.jsx'

export const CobblerCopy = () => {
  return (
    <div className="space-y-6 my-4">
      <Heading>Cobbler Service</Heading>
      <div>
        <Heading>Drop-offs / Measurements</Heading>
        <Paragraph>
          Drop offs are done on Tuesdays and Thursdays. Select a date and time
          below to make a booking.
        </Paragraph>
      </div>
      <div>
        <Heading>Collections</Heading>
        <Paragraph>
          Shoes are collected on Tuesdays and Thursdays by appointment. Once
          shoes have been repaired, you will be notified on this application and
          via email. You can then set up a date and time to collect your shoes.
        </Paragraph>
      </div>
      <div>
        <Heading>Payment</Heading>
        <Paragraph>
          Payment is made at the Kiosk at the Parktown campus.
        </Paragraph>
      </div>
      <Heading>Make a booking</Heading>
    </div>
  )
}
