import React from 'react'

import { classNames } from '../../utils/functions'

export const Button = ({
  children,
  type = 'button',
  tertiary,
  ...otherProps
}) => {
  return (
    <button
      type={type}
      {...otherProps}
      className={classNames(
        tertiary
          ? 'hover:bg-tertiary-mid bg-tertiary-default'
          : 'hover:bg-secondary-mid bg-secondary-default',
        'p-2 w-full text-white text-lg disabled:opacity-75 disabled:cursor-auto'
      )}
    >
      {children}
    </button>
  )
}
