import axios from 'axios'

export function getJWTHeader() {
  return {
    Authorization: `Bearer ${process.env.REACT_APP_SUPABASE_KEY}`,
    apikey: process.env.REACT_APP_SUPABASE_KEY,
  }
}

export const Axios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: getJWTHeader(),
})
