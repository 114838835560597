import React from 'react'
import { Link } from '@reach/router'
import { ChevronRightIcon } from '@heroicons/react/outline'

export const ItemCard = ({
  item,
  icon,
  image,
  setShowSlideOver,
  additionalSetter,
  id = '',
  comingSoon = false
}) => {
  return (
    <div className="w-full">
      <div className="flex bg-white w-full items-center justify-center p-16">
        {image && (
          <img className="block w-auto h-28" src={item.image} alt="logo" />
        )}
        {icon && <item.icon className="text-secondary-default w-28 h-28" />}
      </div>
      {comingSoon ? (
        <div className="h-10 bg-accent-mid text-white flex items-center justify-center text-xl">
          Coming soon!
        </div>
      ) : (
        <div className="h-10 bg-white" />
      )}

      <div className="flex justify-between w-full text-white">
        <div className="flex bg-primary-default w-full items-center px-4 text-lg">
          {item.name}
        </div>
        {setShowSlideOver ? (
          <button
            onClick={() => {
              setShowSlideOver(true)
              additionalSetter({ ...item, id })
            }}
            className="flex justify-center items-center bg-tertiary-default w-14 h-14"
          >
            <ChevronRightIcon className="block w-10 h-10" aria-hidden="true" />
          </button>
        ) : (
          <Link to={item.route}>
            <button className="flex justify-center items-center bg-tertiary-default w-14 h-14">
              <ChevronRightIcon
                className="block w-10 h-10"
                aria-hidden="true"
              />
            </button>
          </Link>
        )}
      </div>
    </div>
  )
}
