import React from 'react'
import { toast } from 'react-toastify'
import CartContext from '../context/CartContext.js'

import {
  setStoredCart,
  setStoredCartVendor,
  setStoredCartUser,
  getStoredCartUser,
  getStoredCartVendor, getStoredSubAccount, setStoredSubAccount,
} from '../local-storage/cartStorage.js'

import {
  setStoredShippingFee,
  getStoredShippingFee,
} from '../local-storage/shippingStorage.js'

import { useUser } from './useUser.js'

export const useCart = () => {
  const { user } = useUser()
  const { cart, setCart } = React.useContext(CartContext)
  const [vendor, setVendor] = React.useState(getStoredCartVendor())
  const [subaccount, setSubAccount] = React.useState(getStoredSubAccount())
  const [shippingFee, setShippingFee] = React.useState(getStoredShippingFee())
  const [userId, setUserId] = React.useState(getStoredCartUser())

  const setCartVendor = vendorId => {
    setVendor(vendorId)
    setStoredCartVendor(vendorId)
  }

  const setPaystackSubAccount = (vendors, vendorId) => {
    const vendor = vendors.filter(vendor => vendor.id === vendorId)[0]
    setSubAccount(vendor.paystack_code)
    setStoredSubAccount(vendor.paystack_code)
  }

  const setCartShippingFee = shippingFee => {
    setShippingFee(shippingFee)
    setStoredShippingFee(shippingFee)
  }

  const setCartUser = userId => {
    setUserId(userId)
    setStoredCartUser(userId)
  }

  const getCartVendor = () => {
    return vendor
  }

  const getCartShippingFee = () => {
    return shippingFee
  }

  const getCartUser = () => {
    return userId
  }

  const getSubAccount = () => {
    return subaccount
  }

  const addToCart = (newItem, shippingFee, vendors) => {
    if (!vendor) {
      setCartVendor(newItem.vendor_id)
    }
    if (!userId) setCartUser(user.id)
    setCartShippingFee(shippingFee)

    const isItemInCart = cart.filter(cartItem => cartItem.id === newItem.id)
    const isNewVendor = cart.filter(
      cartItem => cartItem.vendor_id === newItem.vendor_id
    )

    if (isNewVendor.length === 0 && cart.length !== 0) {
      toast.warning(
        'You can only have products from the same vendor in the cart. Clear your cart or checkout if you' +
          ' want to add this vendor'
      )
      return
    }

    setPaystackSubAccount(vendors, newItem.vendor_id)

    if (isItemInCart.length > 0) {
      toast.warning('This item is already in your cart')
    } else {
      setCart([...cart, newItem])
      setStoredCart([...cart, newItem])
      toast.success(
        `You have successfully added ${newItem.product_name} to your cart!`
      )
    }
  }

  const removeFromCart = itemToRemove => {
    const newCart = cart.filter(cartItem => cartItem.id !== itemToRemove.id)

    setCart([...newCart])
    setStoredCart([...newCart])
    toast.success('Item removed from cart')
  }

  const getCartTotal = () => {
    return cart.reduce((sum, i) => {
      return sum + i.product_price
    }, 0)
  }

  const getOrderTotal = () => {
    return getCartTotal() + shippingFee
  }

  const clearCartVendor = () => {
    setVendor('')
    setStoredCartVendor('')
  }

  const clearCartUser = () => {
    setUserId('')
    setStoredCartUser('')
  }

  const clearCart = () => {
    setCart([])
    setStoredCart([])
  }

  return {
    cart,
    addToCart,
    removeFromCart,
    clearCart,
    clearCartVendor,
    clearCartUser,
    getCartTotal,
    getOrderTotal,
    getCartUser,
    getCartVendor,
    getCartShippingFee,
    getSubAccount,
    setCartUser,
    setCartVendor,
    setCartShippingFee,
  }
}
