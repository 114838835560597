import React from 'react'
import Box from '@mui/material/Box'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import Button from '@mui/material/Button'

// Components
import { CheckoutShipping } from './CheckoutShipping.jsx'
import { CheckoutOrderConfirmation } from './CheckoutOrderConfirmation.jsx'
import { CheckoutComplete } from './CheckoutComplete.jsx'

// Hooks
import { useShippingInfo } from '../../hooks/useShippingInfo.js'
import { usePayment } from '../../hooks/usePayment.js'

const steps = ['Shipping', 'Order Confirmation']

export const CheckoutStepper = () => {
  const { shippingOption } = useShippingInfo()
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set())
  const { onSuccess, onClose, initialisePayment } = usePayment(handleNext)

  const CurrentStepContent = ({ handleNext }) => {
    if (activeStep === 0) {
      return <CheckoutShipping handleNext={handleNext} />
    } else if (activeStep === 1) {
      return <CheckoutOrderConfirmation handleNext={handleNext} />
    }
  }

  const isStepSkipped = step => {
    return skipped.has(step)
  }

  function handleNext() {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <div className="lg:hidden">
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => {
            const stepProps = {}
            const labelProps = {}

            if (isStepSkipped(index)) {
              stepProps.completed = false
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </div>
      <div className="hidden lg:block">
        <Stepper activeStep={activeStep}>
          {steps.map((label, index) => {
            const stepProps = {}
            const labelProps = {}

            if (isStepSkipped(index)) {
              stepProps.completed = false
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            )
          })}
        </Stepper>
      </div>

      {activeStep === steps.length ? (
        <React.Fragment>
          <CheckoutComplete />
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Box sx={{ flex: '1 1 auto' }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="px-3 lg:px-10 py-6">
            <CurrentStepContent handleNext={handleNext} />
          </div>
          <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: '1 1 auto' }} />
            {shippingOption.name === 'Collect' && activeStep === 0 ? (
              <Button form="collect-form" type="submit">
                Next
              </Button>
            ) : null}
            {shippingOption.name === 'Deliver' && activeStep === 0 ? (
              <Button form="deliver-form" type="submit">
                Next
              </Button>
            ) : null}
            {activeStep !== 0 ? (
              <Button onClick={() => initialisePayment(onSuccess, onClose)}>
                {activeStep === steps.length - 1 ? 'Confirm and Pay' : 'Next'}
              </Button>
            ) : null}
          </Box>
        </React.Fragment>
      )}
    </Box>
  )
}
