import React from 'react'
import { PuffLoader } from 'react-spinners'
import { useForm } from 'react-hook-form'

// Components
import { AppHeading } from '../../components/shared/AppHeading'
import { AppSubheading } from '../../components/shared/AppSubheading'
import { Button } from '../../components/shared/Button'
import { FormInput } from '../../components/form'

// Hooks
import { useAuth } from '../../hooks/useAuth'

// Context
import AuthLoadingContext from '../../context/AuthLoadingContext'

const VendorLogin = ({ toggleLoginView }) => {
  const auth = useAuth()
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()

  const handleLogin = values => {
    auth.vendorSignIn(values)
    console.log(values)
  }

  return (
    <div className="mx-auto text-center bg-white shadow-lg max-w-md space-y-3 p-3 lg:mt-20">
      <AppHeading>Concierge Desk</AppHeading>
      <AppSubheading>Sign into the concierge desk</AppSubheading>

      <form
        className="text-left my-3 space-y-4"
        onSubmit={handleSubmit(handleLogin)}
      >
        <FormInput
          id="email"
          errors={errors}
          label="Email"
          register={register}
          type="email"
          required
        />
        <FormInput
          id="password"
          errors={errors}
          label="Password"
          register={register}
          type="password"
          required
        />
        <Button type="submit">Sign In</Button>
      </form>
      <p
        onClick={() => toggleLoginView(true)}
        className="mt-2 cursor-pointer text-primary-default hover:text-primary-mid font-sm"
      >
        Click here if you are a Hollardite
      </p>
    </div>
  )
}

const LoginView = () => {
  const auth = useAuth()
  const { loading } = React.useContext(AuthLoadingContext)
  const [loginView, toggleLoginView] = React.useState(true)

  return (
    <React.Fragment>
      {loginView ? (
        <div className="mx-auto text-center bg-white shadow-lg max-w-md space-y-3 p-3 lg:mt-20">
          <AppHeading>Concierge Desk</AppHeading>
          <AppSubheading>Sign into the concierge desk</AppSubheading>
          {loading ? (
            <div className="flex items-center justify-center">
              <PuffLoader color={'#44b4a6'} size={100} />
            </div>
          ) : (
            <Button onClick={() => auth.signInWithMsal()}>Sign In</Button>
          )}
          {/*<p*/}
          {/*  onClick={() => toggleLoginView(false)}*/}
          {/*  className="cursor-pointer text-primary-default hover:text-primary-mid font-sm"*/}
          {/*>*/}
          {/*  Click here if you are a Vendor*/}
          {/*</p>*/}
        </div>
      ) : (
        <VendorLogin toggleLoginView={toggleLoginView} />
      )}
    </React.Fragment>
  )
}

export default LoginView
