import React from 'react'
import { formatPrice } from '../../utils/functions.js'
import { Link } from '@reach/router'
import { ROUTES } from '../../routes'
import { Button } from './Button.jsx'

// Hooks
import { useCart } from '../../hooks/useCart.js'
import { useShippingInfo } from '../../hooks/useShippingInfo.js'
import { useUser } from '../../hooks/useUser.js'

// Local storage
import { getStoredShippingFee } from '../../local-storage/shippingStorage.js'

export const OrderCartSummary = ({
  isCartSummary,
  isOrderSummary,
  isOrderHistory,
  order,
}) => {
  const { cart, getCartTotal, getOrderTotal } = useCart()
  const { shippingInfo, shippingOption, contactInfo } = useShippingInfo()
  const { user } = useUser()
  let items
  let totalValue
  let shippingCost
  let selectedShippingOption
  let shippingDetails
  let contactDetails

  if (isCartSummary) {
    items = cart
    totalValue = getCartTotal()
    shippingCost = getStoredShippingFee()
    selectedShippingOption = shippingOption.name
    shippingDetails = shippingInfo
    contactDetails = contactInfo
  } else if (isOrderSummary) {
    items = cart
    totalValue = getOrderTotal()
    shippingCost = getStoredShippingFee()
    selectedShippingOption = shippingOption.name
    shippingDetails = shippingInfo
    contactDetails = contactInfo
  } else if (isOrderHistory) {
    items = order.order_items
    totalValue = order.order_total
    shippingCost = order.shipping_cost
    selectedShippingOption = order.shipping_option
    shippingDetails = order.shipping_info
    contactDetails = order.contact_info
  }

  return (
    <div className="flex flex-col bg-white p-5 shadow-sm space-y-4 text-gray-700">
      <div className="flex flex-col space-y-1">
        {items.map(item => (
          <div className="flex flex-col lg:flex-row lg:justify-between pl-3 lg:pl-0 lg:border-0 border-l-4 border-tertiary-default">
            <span>{item.product_name}</span>
            <span>{formatPrice(item.product_price)}</span>
          </div>
        ))}
        {!isCartSummary ? (
          <div className="flex justify-between text-accent-default font-bold">
            <span>Shipping Cost</span>
            <span>{formatPrice(shippingCost)}</span>
          </div>
        ) : null}
      </div>
      <div className="flex flex-col">
        <span className="text-lg">Total Order Cost</span>
        <span className="text-2xl font-bold text-primary-default">
          {formatPrice(totalValue)}
        </span>
      </div>
      <hr />
      {!isCartSummary ? (
        <React.Fragment>
          {/* Shipping section */}
          <div className="space-y-2">
            <h4 className="text-primary-default text-lg">Shipping</h4>
            <div className="flex justify-between">
              <span>Shipping option</span>
              <div className="w-1/4 flex justify-center items-center rounded-full py-1 px-2 text-xs bg-secondary-light text-secondary-default">
                {selectedShippingOption}
              </div>
            </div>
            {selectedShippingOption === 'Deliver' ? (
              <div className="flex justify-between text-sm">
                <span>Shipping Address</span>
                <div className="flex flex-col text-right">
                  {Object.values(shippingDetails)
                    .slice(0, Object.values(shippingDetails).length - 2)
                    .map(item => (
                      <span>{item}</span>
                    ))}
                </div>
              </div>
            ) : null}

            {selectedShippingOption === 'Collect' ? (
              <div className="flex flex-col text-sm">
                <span>
                  <strong>Collection Address: </strong> Cnr 4th Street &
                  Chadwick Rd, Wynberg, Sandton{' '}
                </span>
                <span>
                  <strong>Call Center Number: </strong>
                  <a href="tel:+27765701428">076 570 1428</a> (Weekdays 8am -
                  5pm)
                </span>
                <span>
                  <strong>Email: </strong>
                  <a href="mailto:support@ukhuni.co.za">
                    support@ukhuni.co.za
                  </a>{' '}
                  – Ref: Hollard
                </span>
              </div>
            ) : null}
          </div>

          {/* Contact section */}
          <div className="text-sm">
            <h4 className="text-primary-default text-lg">Contact Details</h4>
            <div className="divide-y-2 lg:divide-y-0 divide-gray-100 divide-solid">
              <div className="flex flex-col lg:flex-row lg:justify-between">
                <span>Full Name</span>
                <span>{`${user.first_name} ${user.last_name}`}</span>
              </div>
              <div className="flex flex-col lg:flex-row lg:justify-between">
                <span>Email</span>
                <span>
                  {selectedShippingOption === 'Collect'
                    ? contactDetails.email
                    : shippingDetails.email}
                </span>
              </div>
              <div className="flex flex-col lg:flex-row lg:justify-between">
                <span>Phone Number</span>
                <span>
                  {selectedShippingOption === 'Collect'
                    ? contactDetails.phoneNumber
                    : shippingDetails.phoneNumber}
                </span>
              </div>
            </div>
          </div>
        </React.Fragment>
      ) : null}

      {isCartSummary ? (
        <Link to={ROUTES.USER.CHECKOUT}>
          <Button tertiary>Proceed to checkout</Button>
        </Link>
      ) : null}
    </div>
  )
}
