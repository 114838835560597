import { SHIPPING_COST } from '../utils/constants.js'

const SHIPPING_LOCAL_STORAGE_KEY = 'dcd_shipping'
const SHIPPING_FEE_LOCAL_STORAGE_KEY = 'dcd_shipping_fee'
const COLLECTION_CONTACT_INFO_KEY = 'dcd_contact_info'
const SHIPPING_OPTION_KEY = 'dcd_shipping_option'

export function getStoredShippingInfo() {
  const storedShippingInfo = localStorage.getItem(SHIPPING_LOCAL_STORAGE_KEY)
  return storedShippingInfo ? JSON.parse(storedShippingInfo) : null
}

export function setStoredShippingInfo(shippingInfo) {
  localStorage.setItem(SHIPPING_LOCAL_STORAGE_KEY, JSON.stringify(shippingInfo))
}

export function clearStoredShippingInfo() {
  localStorage.removeItem(SHIPPING_LOCAL_STORAGE_KEY)
}

export function getStoredShippingFee() {
  const storedShippingFee = localStorage.getItem(SHIPPING_FEE_LOCAL_STORAGE_KEY)
  return storedShippingFee ? JSON.parse(storedShippingFee) : SHIPPING_COST
}

export function setStoredShippingFee(shippingFee) {
  localStorage.setItem(
    SHIPPING_FEE_LOCAL_STORAGE_KEY,
    JSON.stringify(shippingFee)
  )
}

export function clearStoredShippingFee() {
  localStorage.removeItem(SHIPPING_FEE_LOCAL_STORAGE_KEY)
}

export function getStoredContactInfo() {
  const storedContactInfo = localStorage.getItem(COLLECTION_CONTACT_INFO_KEY)
  return storedContactInfo ? JSON.parse(storedContactInfo) : null
}

export function setStoredContactInfo(contactInfo) {
  localStorage.setItem(COLLECTION_CONTACT_INFO_KEY, JSON.stringify(contactInfo))
}

export function clearStoredContactInfo() {
  localStorage.removeItem(COLLECTION_CONTACT_INFO_KEY)
}

export function getStoredShippingOption() {
  const storedShippingOption = localStorage.getItem(SHIPPING_OPTION_KEY)
  return storedShippingOption ? JSON.parse(storedShippingOption) : null
}

export function setStoredShippingOption(shippingOption) {
  localStorage.setItem(SHIPPING_OPTION_KEY, JSON.stringify(shippingOption))
}
