import React from 'react'
import { Link } from '@reach/router'

// Components
import { AppHeading } from '../../components/shared/AppHeading.jsx'
import { AppSubheading } from '../../components/shared/AppSubheading.jsx'
import { EmptyState } from '../../components/shared/EmptyState.jsx'
import { Button } from '../../components/shared/Button.jsx'
import { CartCard } from '../../components/shared/CartCard.jsx'
import { OrderCartSummary } from '../../components/shared/OrderSummary.jsx'
import { BackButton } from '../../components/shared/BackButton.jsx'

// Hooks
import { useCart } from '../../hooks/useCart.js'

// Utils
import { ROUTES } from '../../routes'

const UserCartView = () => {
  const { cart } = useCart()

  return (
    <div>
      <BackButton />
      <AppHeading>Cart</AppHeading>
      <AppSubheading>
        Finalise your cart before heading to checkout!
      </AppSubheading>
      {cart.length > 0 ? (
        <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-10 my-6">
          {/*  Cart Items */}
          <div className="w-full lg:w-5/12 max-h-screen hide-scrollbar bg-white p-5 shadow-sm space-y-3">
            <h4 className="text-lg text-primary-default">{`Your cart (${cart.length})`}</h4>
            {/*  cart items */}
            {cart.map(item => (
              <CartCard item={item} key={item.id} />
            ))}
          </div>
          {/*  Cart Summary */}
          <div className="w-full lg:w-7/12">
            <OrderCartSummary isCartSummary />
          </div>
        </div>
      ) : (
        <EmptyState>
          <h4 className="text-lg">
            You currently have no items in your cart. Click below to shop for
            some items!
          </h4>
          <Link to={ROUTES.USER.SHOP}>
            <Button>Go to shop</Button>
          </Link>
        </EmptyState>
      )}
    </div>
  )
}

export default UserCartView
