import React from 'react'

// Components
import { CartCard } from './CartCard.jsx'
import { OrderCartSummary } from './OrderSummary.jsx'

// Hooks
import { useCart } from '../../hooks/useCart.js'

export const CheckoutOrderConfirmation = () => {
  const { cart } = useCart()

  return (
    <div>
      {cart.length > 0 ? (
        <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-10 my-6">
          {/*  Cart Items */}
          <div className="w-full lg:w-5/12 max-h-screen hide-scrollbar bg-white p-5 shadow-sm space-y-3">
            <h4 className="text-lg text-primary-default">{`Your cart (${cart.length})`}</h4>
            {/*  cart items */}
            {cart.map(item => (
              <CartCard item={item} key={item.id} />
            ))}
          </div>
          {/*  Cart Summary */}
          <div className="w-full lg:w-7/12">
            <OrderCartSummary isOrderSummary />
          </div>
        </div>
      ) : null}
    </div>
  )
}
