import { useQuery, useQueryClient, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { QUERY_KEYS } from '../config/constants'
import dayjs from 'dayjs'
import { sendEmail } from '../utils/functions'
import { Axios } from '../config/axios'
import { useUser } from './useUser'

export const getSpierVoucherHistory = async userId => {
  const { data } = await Axios({
    method: 'GET',
    url: '/RequestedSpierVouchers',
    params: {
      user: `eq.${userId}`,
    },
  })

  if (data) {
    return data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
  }

  return []
}

export const requestSpierVoucher = async ({
  userId,
  first_name,
  email_address,
}) => {
  const { data } = await Axios({
    method: 'GET',
    url: '/SpierVouchers',
    params: {
      select: '*',
      limit: 1,
    },
  })

  const requestedVoucher = data[0]

  await Axios({
    method: 'POST',
    url: '/RequestedSpierVouchers',
    data: {
      voucher_id: requestedVoucher.Code,
      user: userId,
    },
  })

  await Axios({
    method: 'DELETE',
    url: '/SpierVouchers',
    params: {
      id: `eq.${requestedVoucher.id}`,
    },
  })

  try {
    await sendEmail({
      from: 'vouchers@conciergedeskonline.com',
      to: email_address,
      subject: `Nandos Voucher: ${dayjs(new Date()).format('DD MMMM YYYY')} `,
      text: `Hello ${first_name}! Your Spier voucher is: ${requestedVoucher.Code}`,
    })
  } catch (e) {
    toast.error(
      'Ooops. Something went wrong – we could not email your voucher to you.'
    )
  }
}

export const useSpierVoucherHistory = () => {
  const { user } = useUser()
  return useQuery(QUERY_KEYS.SPIER_VOUCHER_HISTORY, () =>
    getSpierVoucherHistory(user.id)
  )
}

export const useRequestVoucher = () => {
  const { user } = useUser()
  const queryClient = useQueryClient()

  return useMutation(
    () =>
      requestSpierVoucher({
        userId: user.id,
        first_name: user.first_name,
        email_address: user.email_address,
      }),
    {
      onSuccess: async () => {
        queryClient.invalidateQueries(QUERY_KEYS.SPIER_VOUCHER_HISTORY)
        toast.success('Voucher successfully requested')
      },
    }
  )
}
