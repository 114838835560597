import React from 'react'
import { toast } from 'react-toastify'

import {
  setStoredShippingInfo,
  setStoredContactInfo,
  getStoredShippingOption,
  setStoredShippingOption,
  setStoredShippingFee,
} from '../local-storage/shippingStorage.js'

import ShippingOptionContext from '../context/ShippingOptionContext.js'
import ShippingInfoContext from '../context/ShippingInfoContext.js'
import ContactInfoContext from '../context/ContactInfoContext.js'

import { SHIPPING_COST } from '../utils/constants.js'

export const shippingOptions = [
  { name: 'Deliver', description: 'Deliver products to your desired address' },
  // { name: 'Collect', description: 'Collect your products from the warehouse' },
]

export const useShippingInfo = () => {
  const { shippingOption, setShippingOption } = React.useContext(
    ShippingOptionContext
  )
  const { shippingInfo, setShippingInfo } =
    React.useContext(ShippingInfoContext)
  const { contactInfo, setContactInfo } = React.useContext(ContactInfoContext)
  const option = getStoredShippingOption()

  // Workaround for weird radio group behaviour
  // React.useEffect(() => {
  //   if (option) {
  //     setShippingOption(shippingOptions.filter(o => o.name === option.name)[0])
  //   } else {
  //     setShippingOption({})
  //   }
  // }, [shippingOption?.name])
  React.useEffect(() => {
    if (getStoredShippingOption()) {
      setShippingOption(shippingOptions.filter(o => o.name === option.name)[0])
    }
  }, [])

  const updateShippingInfo = shippingInfo => {
    setStoredShippingInfo(shippingInfo)
    setShippingInfo(shippingInfo)
    toast.info('Shipping information saved')
  }

  const updateContactInfo = contactInfo => {
    setStoredContactInfo(contactInfo)
    setContactInfo(contactInfo)
    toast.info('Contact information saved')
  }

  const updateShippingOption = shippingOption => {
    setStoredShippingOption(shippingOption)
    setShippingOption(shippingOption)
    if (shippingOption.name === 'Collect') {
      setStoredShippingFee(0)
    } else {
      setStoredShippingFee(SHIPPING_COST)
    }
    toast.info('Shipping preference saved')
  }

  const getShippingOption = () => {
    return getStoredShippingOption()
  }

  return {
    shippingInfo,
    updateShippingInfo,
    contactInfo,
    updateContactInfo,
    shippingOption,
    updateShippingOption,
    getShippingOption,
  }
}
