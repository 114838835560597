import React from 'react'
import { useQuery } from 'react-query'
import { InformationCircleIcon } from '@heroicons/react/solid'
import dayjs from 'dayjs'

// Components
import { AppHeading } from '../../components/shared/AppHeading.jsx'
import { AppSubheading } from '../../components/shared/AppSubheading.jsx'
import { BackButton } from '../../components/shared/BackButton.jsx'

// Utils
import { Axios } from '../../config/axios.js'
import { QUERY_KEYS } from '../../config/constants.js'
import { classNames, formatPrice } from '../../utils/functions.js'
import { Button } from '../../components/shared/Button.jsx'

const getBooking = async bookingId => {
  const { data } = await Axios({
    method: 'GET',
    url: '/Bookings',
    params: {
      select: '*',
      id: `eq.${bookingId}`,
    },
  })

  return data[0]
}

const Notification = () => {
  return (
    <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 my-6">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-yellow-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm text-yellow-700">
            Once the service provider provides you with a quote, they will
            update the price accordingly and you will be able to view it here.
          </p>
        </div>
      </div>
    </div>
  )
}

const UserBookingView = ({ id }) => {
  const { data: booking, isFetching } = useQuery([QUERY_KEYS.BOOKING, id], () =>
    getBooking(id)
  )
  return (
    <div>
      <BackButton />
      <AppHeading>Booking</AppHeading>
      <AppSubheading>Manage your booking</AppSubheading>
      <Notification />
      {isFetching ? (
        <div className="flex flex-col flex-1 gap-5 sm:p-2">
          <div className="flex flex-1 flex-col gap-3">
            <div className="bg-gray-200 w-full animate-pulse h-14 rounded-2xl" />
            <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" />
            <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" />
            <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" />
            <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" />
          </div>
          <div className="mt-auto flex gap-3">
            <div className="bg-gray-200 w-20 h-8 animate-pulse rounded-full" />
            <div className="bg-gray-200 w-20 h-8 animate-pulse rounded-full" />
            <div className="bg-gray-200 w-20 h-8 animate-pulse rounded-full ml-auto" />
          </div>
        </div>
      ) : (
        <div className="bg-white shadow overflow-hidden">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              {booking.service_offering}
            </h3>
            {booking.service_offering === 'Car Wash' ? (
              <a
                className="mt-1 max-w-2xl text-sm text-tertiary-default hover:text-tertiary-mid"
                href="https://qulmdakymnldvnbspzef.supabase.in/storage/v1/object/public/files/BALOYI CAR WAS1_docx 2.doc"
                target="_blank"
                rel="noreferrer noopener"
              >
                Download booking form
              </a>
            ) : null}
          </div>
          <div className="border-t border-gray-200">
            <dl>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Appointment Date
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {dayjs(booking.booking_time).format('DD MMM YYYY, HH:mm')}
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Payment Status
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span
                    className={classNames(
                      booking.paid
                        ? 'bg-tertiary-light text-tertiary-default'
                        : 'bg-secondary-light text-secondary-default',
                      'inline-flex items-center px-2 py-0.5 text-xs font-medium'
                    )}
                  >
                    {booking.paid ? 'Paid' : 'Not Paid'}
                  </span>
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Order Status
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span
                    className={classNames(
                      booking.service_complete
                        ? 'bg-tertiary-light text-tertiary-default'
                        : 'bg-secondary-light text-secondary-default',
                      'inline-flex items-center px-2 py-0.5 text-xs font-medium'
                    )}
                  >
                    {booking.service_complete ? 'Complete' : 'Not started'}
                  </span>
                </dd>
              </div>
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Service Cost
                </dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {booking.service_cost ? (
                    formatPrice(booking.service_cost)
                  ) : (
                    <span className="bg-accent-light text-accent-default inline-flex items-center px-2 py-0.5 text-xs font-medium">
                      Awaiting quotation from service provider
                    </span>
                  )}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Location</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {booking.on_campus ? 'Hollard Campus' : booking.address}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      )}
    </div>
  )
}

export default UserBookingView
