import { useQuery, useQueryClient } from 'react-query'

import { QUERY_KEYS } from '../config/constants.js'
import { Axios } from '../config/axios.js'

const getBigAds = async () => {
  const { data } = await Axios({
    method: 'GET',
    url: '/BigAds',
  })

  return data
}

export const useBigAds = () => {
  const { data, isFetching } = useQuery(QUERY_KEYS.BIG_ADS, getBigAds)

  return { data, isFetching }
}

export const usePrefetchBigAds = () => {
  const queryClient = useQueryClient()
  queryClient.prefetchQuery(QUERY_KEYS.BIG_ADS, getBigAds)
}
