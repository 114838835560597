import { useQuery, useQueryClient } from 'react-query'
import { QUERY_KEYS } from '../config/constants.js'

import { Axios } from '../config/axios.js'

const getVendors = async () => {
  const { data } = await Axios({
    method: 'GET',
    url: '/Vendors',
    params: {
      select: '*',
    },
  })

  return data.filter(item => item.type !== 1)
}

const getVendorById = async (id) => {
  const { data } = await Axios({
    method: 'GET',
    url: '/Vendors',
    params: {
      id: `eq.${id}`,
      select: '*',
    }
  })

  return data
}

export const useVendors = (id) => {
  const fallback = []
  const {
    data = fallback,
    isFetching,
    isLoading,
  } = useQuery(QUERY_KEYS.VENDORS, getVendors)

  const { data: vendor } = useQuery([QUERY_KEYS.VENDOR, id], () => getVendorById(id))

  return {
    data,
    isFetching,
    isLoading,
    vendor
  }
}

export const usePrefetchVendors = () => {
  const queryClient = useQueryClient()
  queryClient.prefetchQuery(QUERY_KEYS.VENDORS, getVendors)
}
