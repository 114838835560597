import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#442359',
    },
    secondary: {
      main: '#e64e00',
    },
    tertiary: {
      main: '#44b4a6',
    },
  },
  shape: {
    borderRadius: 0,
  },
  typography: {
    fontFamily: [
      'Antartida',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 16,
  },
})
