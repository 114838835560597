import React from 'react'
import { QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { queryClient } from './config/reactquery'
import { ToastContainer } from 'react-toastify'

import { Layout } from './components/shared/Layout'

// Routers
import { UserRouter } from './routes/UserRouter'

// Context
import SessionContext from './context/SessionContext'
import GraphContext from './context/GraphContext'
import AuthLoadingContext from './context/AuthLoadingContext'
import CartContext from './context/CartContext.js'
import CheckoutStepperContext from './context/CheckoutStepperContext.js'
import ShippingOptionContext from './context/ShippingOptionContext.js'
import ShippingInfoContext from './context/ShippingInfoContext.js'
import ContactInfoContext from './context/ContactInfoContext.js'
import UserTypeContext from './context/UserTypeContext.js'

// Local storage
import { getStoredGraphData } from './local-storage/graphStorage'
import { getStoredSession } from './local-storage/sessionStorage'
import { getStoredCart } from './local-storage/cartStorage.js'
import {
  getStoredShippingInfo,
  getStoredContactInfo,
} from './local-storage/shippingStorage.js'

import 'react-toastify/dist/ReactToastify.css'

const App = () => {
  const [msalSession, setMsalSession] = React.useState(getStoredSession())
  const [graphData, setGraphData] = React.useState(getStoredGraphData())
  const [loading, setLoading] = React.useState(false)
  const [cart, setCart] = React.useState(getStoredCart())
  const [handlerFunc, setHandlerFunc] = React.useState(() => {})
  const [shippingOption, setShippingOption] = React.useState({})
  const [shippingInfo, setShippingInfo] = React.useState(
    getStoredShippingInfo()
  )
  const [contactInfo, setContactInfo] = React.useState(getStoredContactInfo())
  const [userType, setUserType] = React.useState('')

  return (
    <QueryClientProvider client={queryClient}>
      <SessionContext.Provider value={{ msalSession, setMsalSession }}>
        <GraphContext.Provider value={{ graphData, setGraphData }}>
          <UserTypeContext.Provider value={{ userType, setUserType }}>
            <AuthLoadingContext.Provider value={{ loading, setLoading }}>
              <CartContext.Provider value={{ cart, setCart }}>
                <CheckoutStepperContext.Provider
                  value={{ handlerFunc, setHandlerFunc }}
                >
                  <ShippingOptionContext.Provider
                    value={{ shippingOption, setShippingOption }}
                  >
                    <ShippingInfoContext.Provider
                      value={{ shippingInfo, setShippingInfo }}
                    >
                      <ContactInfoContext.Provider
                        value={{ contactInfo, setContactInfo }}
                      >
                        <Layout>
                          <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                          />
                          <UserRouter />
                        </Layout>
                      </ContactInfoContext.Provider>
                    </ShippingInfoContext.Provider>
                  </ShippingOptionContext.Provider>
                </CheckoutStepperContext.Provider>
              </CartContext.Provider>
            </AuthLoadingContext.Provider>
          </UserTypeContext.Provider>
        </GraphContext.Provider>
      </SessionContext.Provider>
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default App
