const CART_LOCAL_STORAGE_KEY = 'dcd_cart'
const VENDOR_LOCAL_STORAGE_KEY = 'dcd_cart_vendor'
const USER_LOCAL_STORAGE_KEY = 'dcd_cart_user'
const SUBACCOUNT_KEY = 'dcd_subaccount'

export function getStoredCart() {
  const storedCart = localStorage.getItem(CART_LOCAL_STORAGE_KEY)
  return storedCart ? JSON.parse(storedCart) : []
}

export function setStoredCart(cart) {
  localStorage.setItem(CART_LOCAL_STORAGE_KEY, JSON.stringify(cart))
}

export function clearStoredCart() {
  localStorage.removeItem(CART_LOCAL_STORAGE_KEY)
}

export function getStoredCartVendor() {
  const storedCartVendor = localStorage.getItem(VENDOR_LOCAL_STORAGE_KEY)
  return storedCartVendor ? JSON.parse(storedCartVendor) : ''
}

export function setStoredCartVendor(vendor) {
  localStorage.setItem(VENDOR_LOCAL_STORAGE_KEY, JSON.stringify(vendor))
}

export function clearStoredCartVendor() {
  localStorage.removeItem(VENDOR_LOCAL_STORAGE_KEY)
}

export function getStoredCartUser() {
  const storedCartUser = localStorage.getItem(USER_LOCAL_STORAGE_KEY)
  return storedCartUser ? JSON.parse(storedCartUser) : ''
}

export function setStoredCartUser(user) {
  localStorage.setItem(USER_LOCAL_STORAGE_KEY, JSON.stringify(user))
}

export function clearStoredCartUser() {
  localStorage.removeItem(USER_LOCAL_STORAGE_KEY)
}

export function getStoredSubAccount() {
  const storedSubAccount = localStorage.getItem(SUBACCOUNT_KEY)
  return storedSubAccount ? storedSubAccount : ''
}

export function setStoredSubAccount(subaccount) {
  localStorage.setItem(SUBACCOUNT_KEY, subaccount)
}