import React from 'react'
import { Link } from '@reach/router'
import { CheckIcon } from '@heroicons/react/outline'

// Components
import { Button } from './Button.jsx'

// Utils
import { ROUTES } from '../../routes'

export const CheckoutComplete = () => {
  return (
    <div className="my-32 flex flex-col items-center justify-center space-y-3">
      <div className="mx-auto flex items-center justify-center h-24 w-24 rounded-full bg-tertiary-light">
        <CheckIcon
          className="h-20 w-20 text-tertiary-default"
          aria-hidden="true"
        />
      </div>
      <span className="text-tertiary-default text-xl">
        You have successfully completed your order!
      </span>
      <div className="flex space-x-2 w-1/3 justify-center">
        <Link to={ROUTES.USER.HOME}>
          <Button tertiary>Go to home page</Button>
        </Link>
        <Link to={ROUTES.USER.ORDERS}>
          <Button>View your orders</Button>
        </Link>
      </div>
    </div>
  )
}
