import React from 'react'

export const FormCheckbox = ({
  label,
  id,
  errors,
  register,
  required,
  ...otherProps
}) => {

  return (
    <div className="relative flex items-start">
      <div className="flex items-center h-5">
        <input
          id={id}
          name={id}
          type="checkbox"
          className="focus:ring-tertiary-default h-4 w-4 text-tertiary-default border-gray-300"
          {...register(id, { required })}
          {...otherProps}
        />
      </div>
      <div className="ml-3 text-sm">
        <label htmlFor="comments" className="font-medium text-gray-700">
          {label}
        </label>
      </div>
      {errors[id] && (
        <p className="text-sm text-red-500">This field is required</p>
      )}
    </div>
  )
}
