export const ROUTES = {
  USER: {
    HOME: '/',
    BOOKINGS: '/bookings',
    BOOKING: '/bookings/:id',
    MOTHERLAND: '/offerings/motherland',
    PHIC: '/offerings/pauls-homemade-ice-cream',
    SPIER: '/offerings/spier',
    NANDOS: '/offerings/nandos',
    TS_AFRICA: '/offerings/ts-africa',
    ORDERS: '/orders',
    ORDER: '/orders/:id',
    SERVICES: '/services',
    SHOP: '/shop',
    CART: '/cart',
    CHECKOUT: '/checkout',
    VENDORS: '/vendors',
    OFFERINGS: '/offerings',
  },
  SHARED: {
    LOGIN: '/login',
  },
  VENDOR: {
    HOME: '/',
    ORDERS: '/orders',
    ORDER: '/order',
    PRODUCTS: '/products',
  },
  SERVICE_PROVIDER: {
    HOME: '/',
  },
}

export const USER_NAV_ITEMS = [
  { name: 'Home', route: ROUTES.USER.HOME },
  { name: 'Shop', route: ROUTES.USER.SHOP },
  { name: 'Services', route: ROUTES.USER.SERVICES },
  { name: 'Offerings', route: ROUTES.USER.OFFERINGS },
]

export const VENDOR_NAV_ITEMS = [
  { name: 'Home', route: ROUTES.VENDOR.HOME },
  { name: 'Orders', route: ROUTES.VENDOR.ORDERS },
  { name: 'Products', route: ROUTES.VENDOR.PRODUCTS },
]

export const USER_MENU_ITEMS = [
  { name: 'Orders', route: ROUTES.USER.ORDERS },
  { name: 'Bookings', route: ROUTES.USER.BOOKINGS },
  { name: 'Cart', route: ROUTES.USER.CART },
]

export const VENDOR_MENU_ITEMS = [
  { name: 'Orders', route: ROUTES.VENDOR.ORDERS },
  { name: 'Products', route: ROUTES.VENDOR.PRODUCTS },
]
