import React from 'react'
import axios from 'axios'
import { useQuery, useQueryClient } from 'react-query'

import { Axios, getJWTHeader } from '../config/axios'
import { QUERY_KEYS } from '../config/constants'
import {
  clearStoredUser,
  getStoredUser,
  setStoredUser,
  getStoredSession,
  clearStoredSession
} from '../local-storage/userStorage'
import { getStoredGraphData } from '../local-storage/graphStorage'


export const getUser = async (user) => {
  const source = axios.CancelToken.source()

  const authUserData = getStoredGraphData()

  if (!user) return null

  const axiosResponse = await Axios.get('/Users', {
    headers: getJWTHeader(),
    cancelToken: source.token,
    params: {
      id: `eq.${authUserData.id}`,
      select: '*',
    },
  })

  axiosResponse.cancel = () => {
    source.cancel()
  }

  return axiosResponse.data[0]
}

export const useUser = () => {
  const [user, setUser] = React.useState(getStoredUser())
  const [session, setSession] = React.useState(getStoredSession())
  const queryClient = useQueryClient()

  useQuery(QUERY_KEYS.USER, () => getUser(user), {
    enabled: !!user,
    onSuccess: (axiosResponse) => setUser(axiosResponse),
  })

  function clearSession() {
    setSession(null)
    clearStoredSession()
  }

  function updateUser(newUser) {
    setUser(newUser)
    setStoredUser(newUser)
    queryClient.setQueryData(QUERY_KEYS.USER, newUser)
  }

  function clearUser() {
    setUser(null)
    clearStoredUser()
    queryClient.setQueryData(QUERY_KEYS.USER, null)
    queryClient.removeQueries([
      QUERY_KEYS.PRODUCTS,
      QUERY_KEYS.ORDERS,
      QUERY_KEYS.BOOKINGS,
    ])
  }

  return { user, updateUser, clearUser, clearSession }
}
