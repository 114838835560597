import React from 'react'

const UserVendorsView = () => {
  return (
    <div>
     <h1>Vendors</h1> 
    </div>
  )
}

export default UserVendorsView
