import { usePaystackPayment } from 'react-paystack'
import { useShippingInfo } from './useShippingInfo.js'
import { useCart } from './useCart.js'
import { usePlaceOrder } from './usePlaceOrder.js'
import { toast } from 'react-toastify'
import { useVendors } from './useVendors.js'

const PAYSTACK_KEY = process.env.REACT_APP_PAYSTACK_KEY_LIVE

const sanitiseCartTotal = amount => {
  return parseFloat(amount)
    .toFixed(2)
    .toString()
    .split('.')
    .filter(i => i !== '.')
    .join('')
}

export const usePayment = handleNext => {
  const { getOrderTotal, clearCart, getSubAccount } = useCart()
  const { shippingInfo, contactInfo, shippingOption } = useShippingInfo()
  const placeOrder = usePlaceOrder()

  const paymentPayload = {
    email:
      shippingOption?.name === 'Deliver'
        ? shippingInfo?.email
        : contactInfo?.email,
    amount: sanitiseCartTotal(getOrderTotal()),
    publicKey: PAYSTACK_KEY,
    currency: 'ZAR',
    subaccount: getSubAccount(),
  }

  const onSuccess = reference => {
    handleNext()
    toast.success('Payment completed successfully')
    placeOrder(reference.reference)
    clearCart()
  }

  const onClose = () => {
    toast.error('Transaction cancelled')
  }

  const initialisePayment = usePaystackPayment(paymentPayload)

  return { initialisePayment, onClose, onSuccess }
}
