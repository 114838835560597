export const LOGOS = {
  NANDOS:
    'https://qulmdakymnldvnbspzef.supabase.in/storage/v1/object/public/images/app/nandos-01.svg',
  MOTHERLAND:
    'https://qulmdakymnldvnbspzef.supabase.in/storage/v1/object/public/images/app/motherland-coffee-logo.svg',
  SEATTLE:
    'https://qulmdakymnldvnbspzef.supabase.in/storage/v1/object/public/images/app/seattle-coffee-logo.png',
  PHIC: 'https://qulmdakymnldvnbspzef.supabase.co/storage/v1/object/public/images/app/Pauls-Homemade-Ice-Cream.webp',
  TS_AFRICA:
    'https://qulmdakymnldvnbspzef.supabase.co/storage/v1/object/public/images/app/TSAFRIKA%20logo-01.svg',
  SPIER:
    'https://qulmdakymnldvnbspzef.supabase.co/storage/v1/object/public/images/app/spier-gold.jpeg',
}

export const PAYMENT_METHODS = {
  KIOSK: 'Kiosk',
  DEBIT_CARD: 'Debit Card',
  CONCIERGE_DESK: 'Digital Concierge Desk',
  PAYROLL: 'Payroll',
}

export const LOCATION_OPTIONS = {
  CAMPUS: 'Campus',
  HOME: 'Home',
}

export const USER_TYPES = {
  USER: 'user',
  VENDOR: 'vendor',
  SERVICE_PROVIDER: 'service_provider',
}

export const SHIPPING_COST = 250
