const SESSION_LOCAL_STORAGE_KEY = 'dcd_msal_session'

export function getStoredSession() {
  const storedSession = localStorage.getItem(SESSION_LOCAL_STORAGE_KEY)
  return storedSession ? JSON.parse(storedSession) : null
}

export function setStoredSession(session) {
  localStorage.setItem(SESSION_LOCAL_STORAGE_KEY, JSON.stringify(session))
}

export function clearStoredSession() {
  localStorage.removeItem(SESSION_LOCAL_STORAGE_KEY)
}
