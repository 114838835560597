import React from 'react'
import { ShoppingCartIcon } from '@heroicons/react/outline'
import { PuffLoader } from 'react-spinners'

// Hooks
import { useVendors } from '../../hooks/useVendors.js'
import { useProducts } from '../../hooks/useProducts.js'
import { useCart } from '../../hooks/useCart.js'

// Components
import { AppHeading } from '../../components/shared/AppHeading.jsx'
import { AppSubheading } from '../../components/shared/AppSubheading.jsx'
import { SelectMenu } from '../../components/shared/SelectMenu.jsx'

// Utility functions
import { formatPrice } from '../../utils/functions.js'
import { SHIPPING_COST } from '../../utils/constants.js'
import ProductCardModal from '../../components/shared/ProductCardModal.jsx'

const ProductCard = ({ item, setOpen, setExpandedProduct, vendors }) => {
  const cart = useCart()
  return (
    <div className="w-full cursor-pointer">
      <div onClick={() => {
        setOpen(true)
        setExpandedProduct(item)
      }}  className="flex w-full items-center justify-center">
        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
        <img src={item.image_url} alt={`Image of the ${item.product_name}`} />
      </div>
      <div className="w-full bg-accent-light px-4 py-3">
        <span className="text-accent-default text-ellipsis overflow-hidden">
          {item.product_name}
        </span>
      </div>
      <div className="flex justify-between w-full text-white">
        <div className="flex bg-primary-default w-full items-center px-4 text-lg">
          {formatPrice(item.product_price)}
        </div>
        <button
          onClick={() => cart.addToCart(item, SHIPPING_COST, vendors)}
          className="flex justify-center items-center bg-tertiary-default px-2 w-full h-14 hover:bg-tertiary-light hover:text-tertiary-default"
        >
          <span className="text-sm w-full">Add to cart</span>
          <ShoppingCartIcon className="block w-8 h-8" aria-hidden="true" />
        </button>
      </div>
    </div>
  )
}

const UserShopView = () => {
  const [expandProductCard, setExpandProductCard] = React.useState(false)
  const [expandedProduct, setExpandedProduct] = React.useState({})
  const { data: vendors, isFetching: isFetchingVendors } = useVendors()
  const { data: products, isFetching: isFetchingProducts } = useProducts()

  const [vendorOptions, setVendorOptions] = React.useState([])
  const [currentVendor, setCurrentVendor] = React.useState({})
  const [categoryOptions, setCategoryOptions] = React.useState([])
  const [currentCategory, setCurrentCategory] = React.useState({})
  const [filteredProducts, setFilteredProducts] = React.useState([])

  React.useLayoutEffect(() => {
    if (!isFetchingVendors) {
      const transformedVendorList = vendors.map(vendor => ({
        name: vendor.vendor_name,
        id: vendor.id,
        categories: vendor.product_categories,
      }))

      const tempCategories = transformedVendorList[1].categories.map(
        category => ({
          name: category,
          id: category,
        })
      )

      setVendorOptions(transformedVendorList)
      setCurrentVendor(transformedVendorList[transformedVendorList.findIndex(item => item.name === 'Arion Power')])

      setCategoryOptions(tempCategories)
      setCurrentCategory(tempCategories[0])
    }
  }, [isFetchingVendors, vendors])

  React.useEffect(() => {
    if (vendorOptions.length > 0) {
      const tempCategories = currentVendor.categories.map(category => ({
        name: category,
        id: category
      }))

      setCategoryOptions(tempCategories)
      setCurrentCategory(tempCategories[0])
    }
  }, [currentVendor.id])

  React.useEffect(() => {
    if (!isFetchingProducts) {
      const filteredProds = products.filter(
        product => product.product_category === currentCategory.id
      )
      setFilteredProducts(filteredProds)
    }
  }, [currentCategory.id, isFetchingProducts, products])

  return (
    <div>
      <ProductCardModal product={expandedProduct} setOpen={setExpandProductCard} open={expandProductCard} />
      <AppHeading>Shop</AppHeading>
      <AppSubheading>Shop for discounted products</AppSubheading>
      <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-10 my-5">
        {/*  Filters begin */}
        <div className="w-full lg:w-1/4 h-1/4 p-6 bg-white space-y-3">
          {/*   Filter by service provider and categories */}
          <div>
            <SelectMenu
              label="Select a vendor"
              options={vendorOptions}
              selected={currentVendor}
              setSelected={setCurrentVendor}
            />
          </div>
          <div>
            <SelectMenu
              label="Select a category"
              options={categoryOptions}
              selected={currentCategory}
              setSelected={setCurrentCategory}
            />
          </div>
        </div>
        {/*  Filters end */}
        {/*  Products listing begins*/}
        <div className="w-full lg:w-3/4 grid grid-cols-1 lg:grid-cols-3 gap-3">
          {!isFetchingProducts ? (
            filteredProducts.map(product => (
              <ProductCard key={product.id} item={product} vendors={vendors} setExpandedProduct={setExpandedProduct} setOpen={setExpandProductCard} />
            ))
          ) : (
            <div className="w-full lg:w-3/4 flex items-center justify-center">
              <PuffLoader color={'#44b4a6'} size={100} />
            </div>
          )}
        </div>
        {/*  Products listing ends*/}
      </div>
    </div>
  )
}

export default UserShopView
