import React from 'react'
import { PuffLoader } from 'react-spinners'
import dayjs from 'dayjs'
import { Link } from '@reach/router'

// Components
import { AppHeading } from '../../components/shared/AppHeading.jsx'
import { AppSubheading } from '../../components/shared/AppSubheading.jsx'
import { Table, TableData } from '../../components/shared/Table.jsx'
import { EmptyState } from '../../components/shared/EmptyState.jsx'

// Hooks
import { useBookings } from '../../hooks/useBookings.js'
import { ROUTES } from '../../routes'

// Utils
import { classNames } from '../../utils/functions.js'

const headers = [
  'Booking Id',
  'Service',
  'Appointment Date',
  'Location',
  'Payment',
  'Status',
]

function UserBookingsView() {
  const { userBookings, isFetchingUserBookings } = useBookings()

  return (
    <div>
      <AppHeading>Bookings</AppHeading>
      <AppSubheading>View and manage your bookings</AppSubheading>
      {isFetchingUserBookings ? (
        <div className="flex items-center justify-center my-10">
          <PuffLoader color={'#44b4a6'} size={100} />
        </div>
      ) : null}
      {!isFetchingUserBookings && userBookings.length > 0 ? (
        <Table headers={headers} actionButton>
          {userBookings.map((booking, bookingIdx) => (
            <tr
              key={booking.id}
              className={bookingIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
            >
              <TableData>{booking.id}</TableData>
              <TableData>{booking.service_offering}</TableData>
              <TableData>
                {dayjs(booking.booking_time).format('DD MMM YYYY, HH:mm')}
              </TableData>
              <TableData>
                {booking.on_campus ? 'Hollard Campus' : 'Home Address'}
              </TableData>
              <TableData>
                <span
                  className={classNames(
                    booking.paid
                      ? 'bg-tertiary-light text-tertiary-default'
                      : 'bg-secondary-light text-secondary-default',
                    'inline-flex items-center px-2 py-0.5 text-xs font-medium'
                  )}
                >
                  {booking.paid ? 'Paid' : 'Not Paid'}
                </span>
              </TableData>
              <TableData>
                <span
                  className={classNames(
                    booking.service_complete
                      ? 'bg-tertiary-light text-tertiary-default'
                      : 'bg-secondary-light text-secondary-default',
                    'inline-flex items-center px-2 py-0.5 text-xs font-medium'
                  )}
                >
                  {booking.service_complete ? 'Complete' : 'Not started'}
                </span>
              </TableData>
              <TableData>
                <Link to={`${ROUTES.USER.BOOKINGS}/${booking.id}`}>
                  <span className="text-tertiary-default hover:bg-tertiary-light p-1">
                    View Booking
                  </span>
                </Link>
              </TableData>
            </tr>
          ))}
        </Table>
      ) : null}
      {!isFetchingUserBookings && userBookings.length === 0 ? (
        <EmptyState>
          <h4 className="text-lg">You currently have no bookings</h4>
        </EmptyState>
      ) : null}
    </div>
  )
}

export default UserBookingsView
