import React from 'react'
// Icons
import { GrAppleAppStore } from 'react-icons/gr'
import { FaGooglePlay } from 'react-icons/fa'

// Components
import { AppHeading } from '../../components/shared/AppHeading'
import { AppSubheading } from '../../components/shared/AppSubheading'
import { Button } from '../../components/shared/Button'

import { LOGOS } from '../../utils/constants'
import { BackButton } from '../../components/shared/BackButton.jsx'

const UserTSAFricaView = () => {
  return (
    <div className="pb-20">
      <BackButton />
      <AppHeading>Food and beverage offerings</AppHeading>
      <AppSubheading>Your latest discounts and offerings</AppSubheading>
      <div className="flex flex-col md:flex-row space-y-2 md:space-x-4 my-4 items-center">
        <div className="bg-white p-8">
          <img className="block w-28 h-auto" src={LOGOS.TS_AFRICA} alt="logo" />
        </div>
        <div>
          <h1 className="text-primary-default text-2xl">
            Ordering food made easy!
          </h1>
          <p className="text-gray-700">
            TSAfrika’s intelipos mobile app is designed to make your ordering as
            easy and convenient as possible. You can place your orders for
            Lionel House, Florence House and the Spaza. With a selected menu you
            can order from your desk and come and collect at your leisure.
          </p>
        </div>
      </div>
      <div>
        <h1 className="text-primary-default text-xl">Here's how:</h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 my-4">
          {/* Step 1 */}
          <div className="flex flex-col justify-between space-y-4 bg-tertiary-light p-4">
            <div className="flex items-center space-x-3">
              <div className="flex items-center justify-center rounded-full text-primary-default bg-primary-light p-4 h-12 w-12 font-bold text-2xl">
                1.
              </div>
              <h2 className="uppercase text-2xl text-primary-default">
                Download App
              </h2>
            </div>
            <p className="text-primary-default">
              Download the InteliPOS app for your iPhone or Android device.
            </p>
            <div className="flex justify-around text-tertiary-default">
              <a
                href="https://apps.apple.com/za/app/intelipos/id1479195594"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center hover:text-tertiary-mid"
              >
                <GrAppleAppStore size={60} />
                <span className="uppercase font-bold text-sm">
                  Go to App Store
                </span>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.intelipos"
                target="_blank"
                rel="noopener noreferrer"
                className="flex flex-col items-center hover:text-tertiary-mid"
              >
                <FaGooglePlay size={60} />
                <span className="uppercase font-bold text-sm">
                  Go to Play Store
                </span>
              </a>
            </div>
          </div>
          {/* Step 2 */}
          <div className="flex flex-col justify-between space-y-4 bg-tertiary-light p-4">
            <div className="flex items-center space-x-3">
              <div className="flex items-center justify-center rounded-full text-primary-default bg-primary-light p-4 h-12 w-12 font-bold text-2xl">
                2.
              </div>
              <h2 className="uppercase text-2xl text-primary-default">
                Register on the app
              </h2>
            </div>
            <p className="text-primary-default">
              Register on the application and setup your prepaid wallet so you
              can place orders.
              <br />
              You can download the guide below if you are unsure of the process.
            </p>
            <a
              href="https://qulmdakymnldvnbspzef.supabase.co/storage/v1/object/public/files/Mobile%20App%20for%20Concierge.docx"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button tertiary>Download guide</Button>
            </a>
          </div>
          {/* Step 3 */}
          <div className="flex flex-col space-y-4 bg-tertiary-light p-4">
            <div className="flex items-center space-x-3">
              <div className="flex items-center justify-center rounded-full text-primary-default bg-primary-light p-4 h-12 w-12 font-bold text-2xl">
                3.
              </div>
              <h2 className="uppercase text-2xl text-primary-default">
                Place your order
              </h2>
            </div>
            <p className="text-primary-default">
              At this point, you should be setup and ready to go. You can now
              place your order and collect it once it is ready!
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserTSAFricaView
