import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'

// Components
import { Button } from './Button'

export function SlideOver({
  show,
  setShow,
  title,
  subtitle,
  actionButtonLabel = 'Submit',
  disableActionButton,
  buttonAction,
  handleFormSubmitFunc,
  handleSubmit,
  children,
}) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden z-10"
        onClose={setShow}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-2xl">
                <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div>
                    {/* Header */}
                    <div className="px-4 py-6 bg-gray-50 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-2xl font-medium text-primary-default">
                            {title}
                          </Dialog.Title>
                          <p className="text-lg text-tertiary-default">
                            {subtitle}
                          </p>
                        </div>
                        <div className="h-7 flex items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={() => setShow(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit(handleFormSubmitFunc)}>
                    {/* Body / Content */}
                    <div className="px-6 py-3 flex-1">{children}</div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 px-4 border-t border-gray-200 py-5 sm:px-6">
                      <div className="space-x-3 flex justify-end">
                        <Button tertiary onClick={() => setShow(false)}>
                          Cancel
                        </Button>
                        {buttonAction ? (
                          <Button
                            onClick={buttonAction}
                            disabled={disableActionButton}
                          >
                            {actionButtonLabel}
                          </Button>
                        ) : (
                          <Button type="submit" disabled={disableActionButton}>
                            {actionButtonLabel}
                          </Button>
                        )}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
