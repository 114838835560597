import React from 'react'

export const FormInput = ({
  type = 'text',
  id,
  label,
  errors,
  register,
  required,
  ...otherProps
}) => {
  return (
    <div>
      <label htmlFor={id} className="block text-sm font-medium text-gray-700">
        {label}
      </label>
      <input
        name={id}
        id={id}
        className="shadow-sm placeholder-gray-300 focus:ring-tertiary-default focus:border-tertiary-default block w-full sm:text-sm border-gray-300"
        type={type}
        {...register(id, { required })}
        {...otherProps}
      />
      {errors[id] && (
        <p className="text-sm text-red-500">This field is required</p>
      )}
    </div>
  )
}
