const USER_LOCALSTORAGE_KEY = 'hollard_user'
const USER_SESSION_LOCALSTORAGE_KEY = 'hollard_user_session'
const SUPABASE_LOCALSTORAGE_KEY = 'supabase.auth.token'

/**
 *
 * @User
 */
export function getStoredUser() {
  const storedUser = localStorage.getItem(USER_LOCALSTORAGE_KEY)
  return storedUser ? JSON.parse(storedUser) : null
}

export function setStoredUser(user) {
  localStorage.setItem(USER_LOCALSTORAGE_KEY, JSON.stringify(user))
}

export function clearStoredUser() {
  localStorage.removeItem(USER_LOCALSTORAGE_KEY)
}

export function getUserAuth() {
  const supabaseUserData = localStorage.getItem(SUPABASE_LOCALSTORAGE_KEY)
  return supabaseUserData ? JSON.parse(supabaseUserData) : null
}

/**
 *
 * @Session
 */
export function getStoredSession() {
  const storedSession = localStorage.getItem(USER_SESSION_LOCALSTORAGE_KEY)
  return storedSession ? JSON.parse(storedSession) : null
}

export function setStoredSession(session) {
  localStorage.setItem(USER_SESSION_LOCALSTORAGE_KEY, JSON.stringify(session))
}

export function clearStoredSession() {
  localStorage.removeItem(USER_SESSION_LOCALSTORAGE_KEY)
}
