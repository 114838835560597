import React from 'react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { ChevronRightIcon } from '@heroicons/react/outline'

export const BigAdsCard = ({
  serviceProvider,
  setCurrentBigAd,
  setShowModal,
}) => {
  return (
    <div className="w-full">
      <div className="flex bg-white items-center justify-center p-16">
        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
        <img
          className="w-auto h-28"
          src={serviceProvider.logo_url}
          alt={`${serviceProvider.name} background image`}
        />
        {/*<div className="absolute inset-0 bg-primary-mid mix-blend-multiply" />*/}
      </div>

      <div className="h-10 bg-white" />
      <div className="flex justify-between w-full text-white">
        <div className="flex bg-primary-default w-full items-center px-4 text-lg">
          {serviceProvider.name}
        </div>
        <button
          onClick={() => {
            setCurrentBigAd(serviceProvider)
            setShowModal(true)
          }}
          className="flex justify-center items-center bg-tertiary-default w-14 h-14"
        >
          <ChevronRightIcon className="block w-10 h-10" aria-hidden="true" />
        </button>
      </div>
      {/*<div className="w-full py-2 px-3 bg-primary-light text-primary-default">*/}
      {/*  {serviceProvider.name}*/}
      {/*</div>*/}
      {/*<button*/}
      {/*  onClick={() => {*/}
      {/*    setCurrentBigAd(serviceProvider)*/}
      {/*    setShowModal(true)*/}
      {/*  }}*/}
      {/*  className="w-full flex space-x-3 bg-gray-200 text-primary-default p-3"*/}
      {/*>*/}
      {/*  <ChevronDownIcon className="block h-6 w-6" />*/}
      {/*  <span>Show More</span>*/}
      {/*</button>*/}
    </div>
  )
}
