import { useQuery, useQueryClient } from 'react-query'

import { Axios } from '../config/axios.js'

import { useUser } from './useUser.js'
import { QUERY_KEYS } from '../config/constants.js'

const getUserOrders = async userId => {
  const { data } = await Axios({
    method: 'GET',
    url: '/Orders',
    params: {
      select: '*',
      user_id: `eq.${userId}`,
    },
  })

  return data
}

export const useOrders = () => {
  const { user } = useUser()
  const { data: userOrders, isFetching: isFetchingUserOrders } = useQuery(
    QUERY_KEYS.ORDERS,
    () => getUserOrders(user.id)
  )

  return { userOrders, isFetchingUserOrders }
}

export const usePrefetchUserOrders = () => {
  const { user } = useUser()
  const queryClient = useQueryClient()
  queryClient.prefetchQuery(QUERY_KEYS, () => getUserOrders(user.id))
}
