import React from 'react'
import { Link } from '@reach/router'

//Icons
import { ChevronRightIcon } from '@heroicons/react/outline'
import { BsBasket } from 'react-icons/bs'
import { MdOutlineFastfood, MdOutlineRoomService } from 'react-icons/md'
import { BiHomeAlt } from 'react-icons/bi'
import { BsCalendarRange } from 'react-icons/bs'

// Components
import { AppHeading } from '../../components/shared/AppHeading'
import { AppSubheading } from '../../components/shared/AppSubheading'

// Util
import { ROUTES } from '../../routes'

// Hooks
import { usePrefetchVoucherHistory } from '../../hooks/useVouchers'
import { usePrefetchServiceProviders } from '../../hooks/useServiceProviders'
import { usePrefetchVendors } from '../../hooks/useVendors.js'
import { usePrefetchProducts } from '../../hooks/useProducts.js'
import { usePrefetchUserOrders } from '../../hooks/useOrders.js'
import { usePrefetchBigAds } from '../../hooks/useBigAds.js'

import purpleShopLogo from '../../assets/purple-shop.png'

const homeItems = [
  { name: 'Shop', route: ROUTES.USER.SHOP, icon: BsBasket },
  { name: 'Services', route: ROUTES.USER.SERVICES, icon: MdOutlineRoomService },
  { name: 'Offerings', route: ROUTES.USER.OFFERINGS, icon: MdOutlineFastfood },
  //   {
  //     name: 'House Call Services',
  //     route: ROUTES.USER.OFFERINGS,
  //     icon: BiHomeAlt,
  //   },
  //   {
  //     name: 'Experiential Event Planning',
  //     route: ROUTES.USER.OFFERINGS,
  //     icon: BsCalendarRange,
  //   },
]

const CustomHomeCard = () => {
  return (
    <div className="w-full">
      <div className="flex bg-white items-center justify-center p-16">
        {/* eslint-disable-next-line jsx-a11y/img-redundant-alt */}
        <img
          className="w-auto h-28"
          src={purpleShopLogo}
          alt={`${purpleShopLogo} logo`}
        />
      </div>
      <div className="flex justify-between w-full text-white">
        <div className="flex bg-primary-default w-full items-center px-4 text-lg">
          Purple Shop
        </div>
        <a
          href="https://purpleshop.co.za/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex justify-center items-center bg-tertiary-default w-14 h-14"
        >
          <ChevronRightIcon className="block w-10 h-10" aria-hidden="true" />
        </a>
      </div>
    </div>
  )
}

// Local Components
const HomeCard = ({ item }) => {
  return (
    <Link to={item.route}>
      <div className="w-full">
        <div className="flex bg-white w-full items-center justify-center p-16">
          <item.icon className="text-secondary-default w-28 h-28" />
        </div>
        <div className="flex justify-between w-full text-white">
          <div className="flex bg-primary-default w-full items-center px-4 text-lg">
            {item.name}
          </div>
          <Link to={item.route}>
            <button className="flex justify-center items-center bg-tertiary-default w-14 h-14">
              <ChevronRightIcon
                className="block w-10 h-10"
                aria-hidden="true"
              />
            </button>
          </Link>
        </div>
      </div>
    </Link>
  )
}

const UserHomeView = () => {
  usePrefetchVoucherHistory()
  usePrefetchServiceProviders()
  usePrefetchVendors()
  usePrefetchProducts()
  usePrefetchUserOrders()
  usePrefetchBigAds()

  return (
    <div>
      <AppHeading>Welcome to the Hollard Concierge Desk</AppHeading>
      <AppSubheading>
        Browse our shop, offerings and services below.
      </AppSubheading>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 my-4">
        {homeItems.map(item => (
          <HomeCard key={item.route} item={item} />
        ))}
        <CustomHomeCard />
      </div>
    </div>
  )
}

export default UserHomeView
