import React from 'react'
import { useIsMutating } from 'react-query'
import { PuffLoader } from 'react-spinners'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

// Icons
import { GrAppleAppStore } from 'react-icons/gr'
import { FaGooglePlay } from 'react-icons/fa'
import { RiCoupon2Fill as VoucherIcon } from 'react-icons/ri'

// Components
import { AppHeading } from '../../components/shared/AppHeading'
import { AppSubheading } from '../../components/shared/AppSubheading'
import { Button } from '../../components/shared/Button'

import { LOGOS } from '../../utils/constants'
import { canRequestCoupon, sendEmail } from '../../utils/functions'
import { BackButton } from '../../components/shared/BackButton.jsx'

// Hooks
import { useVouchers } from '../../hooks/useVouchers'
import {
  useSpierVoucherHistory,
  useRequestVoucher,
} from '../../hooks/useSpierVoucher'

dayjs.extend(relativeTime)

const UserSpierView = () => {
  const { isFetchingVouchers } = useVouchers()
  const isMutating = useIsMutating()

  const { data: voucherHistory, isLoading: isFetchingVoucherHistory } =
    useSpierVoucherHistory()
  const requestVoucherMutation = useRequestVoucher()

  React.useEffect(() => {
    console.log(voucherHistory)
  }, [voucherHistory])

  return (
    <div className="pb-20">
      <BackButton />
      <AppHeading>Food and beverage offerings</AppHeading>
      <AppSubheading>Your latest discounts and offerings</AppSubheading>
      <div className="flex flex-col md:flex-row space-y-2 md:space-x-4 my-4 items-center">
        <div className="bg-white p-8">
          <img className="block w-auto h-28" src={LOGOS.SPIER} alt="logo" />
        </div>
        <div>
          <h1 className="text-primary-default text-2xl">
            Hollardite, welcome to Spier on the Concierge Desk!
          </h1>
          <p className="text-gray-700">
            Hollard and Spier are part of the same family, so you get to share
            in the benefit of 30% off all our wines online.
          </p>
        </div>
      </div>
      <div>
        <h1 className="text-primary-default text-xl">Here's how:</h1>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4 my-4">
          {/* Step 1 */}
          <div className="flex flex-col justify-between space-y-4 bg-tertiary-light p-4">
            <div className="flex items-center space-x-3">
              <div className="flex items-center justify-center rounded-full text-primary-default bg-primary-light p-4 h-12 w-12 font-bold text-2xl">
                1.
              </div>
              <h2 className="uppercase text-2xl text-primary-default">
                Create your account
              </h2>
            </div>
            <p className="text-primary-default">
              Click on the link below to create an account on Spier's website.{' '}
              <br />
              <strong>Note:</strong> your discount will only work if you have
              created an account.
            </p>
            <a
              href="https://shop.spier.co.za/pages/partners-registration"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button>Create account</Button>
            </a>
          </div>
          {/* Step 2 */}
          <div className="flex flex-col justify-between space-y-4 bg-tertiary-light p-4">
            <div className="flex items-center space-x-3">
              <div className="flex items-center justify-center rounded-full text-primary-default bg-primary-light p-4 h-12 w-12 font-bold text-2xl">
                2.
              </div>
              <h2 className="uppercase text-2xl text-primary-default">
                Request a voucher
              </h2>
            </div>
            <p className="text-primary-default">
              Request a voucher in the section or button below. You are limited
              to a single voucher per month.
            </p>
            {!isFetchingVoucherHistory && (
              <Button
                tertiary
                disabled={
                  canRequestCoupon(voucherHistory[0]?.created_at) === false
                }
                onClick={() => requestVoucherMutation.mutate()}
              >
                Request a voucher
              </Button>
            )}
          </div>
          {/* Step 3 */}
          <div className="flex flex-col justify-between space-y-4 bg-tertiary-light p-4">
            <div className="flex items-center space-x-3">
              <div className="flex items-center justify-center rounded-full text-primary-default bg-primary-light p-4 h-12 w-12 font-bold text-2xl">
                3.
              </div>
              <h2 className="uppercase text-2xl text-primary-default">
                Login to Spier and Shop
              </h2>
            </div>
            <p className="text-primary-default">
              Log into your Spier account and add to your basket and enter the
              discount code at checkout!
            </p>
            <a
              href="https://shop.spier.co.za/pages/partners-registration"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button tertiary>Login</Button>
            </a>
          </div>
        </div>
      </div>
      {voucherHistory?.length > 0 && (
        <div className="flex flex-col space-y-4 text-tertiary-default bg-tertiary-light w-full lg:w-1/3 p-8 my-3">
          <div className="flex space-x-4 items-center">
            <VoucherIcon size={60} />
            {isFetchingVoucherHistory || requestVoucherMutation.isLoading ? (
              <PuffLoader color={'#44b4a6'} size={100} />
            ) : (
              <div className="text-primary-default flex flex-col">
                {voucherHistory && (
                  <React.Fragment>
                    <h4 className="text-lg">
                      <strong>Voucher Code: {'  '} </strong>
                      <span>{voucherHistory[0]?.voucher_id}</span>
                    </h4>
                    <span className="text-sm">{`Requested ${dayjs(
                      new Date(voucherHistory[0]?.created_at)
                    ).fromNow()} (${dayjs(
                      new Date(voucherHistory[0]?.created_at)
                    ).format('DD MMM YYYY')})`}</span>
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
          {!isFetchingVouchers && !requestVoucherMutation.isLoading ? (
            <p>
              This is your non-expiry voucher code to use to qualify for a
              discount as long as your are a Hollardite.
            </p>
          ) : null}
        </div>
      )}

      <div className="space-y-6">
        <h1 className="text-primary-default text-xl">Remember the following</h1>
        <ul className="list-disc list-inside text-gray-700">
          <li>
            Delivery fees start at R150 per 6-bottle case and accumulates to R50
            per 6-bottle case thereafter. We suggest partnering with a colleague
            and order together to split delivery costs. Collections from Spier
            Wine Farm are free.
          </li>
          <li>
            Your 30% off discount does not apply to wines that are currently on
            promotion or curated packs.
          </li>
          <li>
            Wine bought is for personal consumption and may not be sold to
            another person.
          </li>
        </ul>
      </div>
    </div>
  )
}

export default UserSpierView
