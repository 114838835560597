import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { navigate } from '@reach/router'

// Components
import { AppHeading } from '../../components/shared/AppHeading.jsx'
import { AppSubheading } from '../../components/shared/AppSubheading.jsx'
import { CheckoutStepper } from '../../components/shared/CheckoutStepper.jsx'

// Hooks
import { useCart } from '../../hooks/useCart.js'

// Utils
import { ROUTES } from '../../routes'
import { theme } from '../../config/materialTheme.js'

const UserCheckoutView = () => {
  const { cart } = useCart()

  React.useEffect(() => {
    if (cart.length === 0) {
      navigate(ROUTES.USER.CART)
    }
  }, [cart.length, cart])

  return (
    <div>
      <AppHeading>Checkout</AppHeading>
      <AppSubheading>Follow the steps to complete your order</AppSubheading>
      <div className="my-6">
        <ThemeProvider theme={theme}>
          <CheckoutStepper />
        </ThemeProvider>
      </div>
    </div>
  )
}

export default UserCheckoutView
