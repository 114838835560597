import React from 'react'
import dayjs from 'dayjs'
import { Link } from '@reach/router'
import { PuffLoader } from 'react-spinners'

// Components
import { AppSubheading } from '../../components/shared/AppSubheading.jsx'
import { AppHeading } from '../../components/shared/AppHeading.jsx'
import { Table, TableData } from '../../components/shared/Table.jsx'
import { EmptyState } from '../../components/shared/EmptyState.jsx'

// Hooks
import { useOrders } from '../../hooks/useOrders.js'

// Utils
import { formatPrice } from '../../utils/functions.js'
import { ROUTES } from '../../routes'

const headers = [
  'Transaction ID',
  'Ordered At',
  'Order Total',
  'Delivery Option',
]

const UserOrdersView = () => {
  const { userOrders, isFetchingUserOrders } = useOrders()
  return (
    <div>
      <AppHeading>Orders</AppHeading>
      <AppSubheading>View all of your shop orders</AppSubheading>
      {isFetchingUserOrders ? (
        <div className="flex items-center justify-center my-10">
          <PuffLoader color={'#44b4a6'} size={100} />
        </div>
      ) : null}
      {!isFetchingUserOrders && userOrders.length > 0 ? (
        <Table headers={headers} actionButton>
          {userOrders.map((order, orderIdx) => (
            <tr
              key={order.id}
              className={orderIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
            >
              <TableData>{order.tx_reference}</TableData>
              <TableData>
                {dayjs(order.created_at).format('DD MMM YYYY, HH:mm')}
              </TableData>
              <TableData>{formatPrice(order.order_total)}</TableData>
              <TableData>
                <span className="inline-flex items-center px-2 py-0.5 text-xs font-medium bg-secondary-light text-secondary-default">
                  {order.shipping_option}
                </span>
              </TableData>
              <TableData>
                <Link to={`${ROUTES.USER.ORDERS}/${order.id}`}>
                  <span className="text-tertiary-default hover:bg-tertiary-light p-1">
                    View Order
                  </span>
                </Link>
              </TableData>
            </tr>
          ))}
        </Table>
      ) : null}
      {!isFetchingUserOrders && userOrders.length === 0 ? (
        <EmptyState>
          <h4 className="text-lg">You currently have no orders</h4>
        </EmptyState>
      ) : null}
    </div>
  )
}

export default UserOrdersView
