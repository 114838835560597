import { useQueryClient, useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { QUERY_KEYS } from '../config/constants.js'

// Hooks
import { useUser } from './useUser.js'
import { useShippingInfo } from './useShippingInfo.js'
import { useCart } from './useCart.js'

// Local storage
import { getStoredShippingFee } from '../local-storage/shippingStorage.js'

import { Axios } from '../config/axios.js'

const placeOrder = async (order, tx_reference) => {
  await Axios({
    method: 'POST',
    url: '/Orders',
    data: {
      ...order,
      tx_reference,
    },
  })
}

export const usePlaceOrder = () => {
  const { user } = useUser()
  const { shippingInfo, contactInfo, shippingOption } = useShippingInfo()
  const { getCartVendor, getOrderTotal, cart, getCartTotal } = useCart()
  const queryClient = useQueryClient()

  const order = {
    vendor_id: getCartVendor(),
    user_id: user.id,
    shipping_option: shippingOption.name,
    contact_info: contactInfo,
    shipping_info: shippingInfo,
    shipping_cost: getStoredShippingFee(),
    cart_total: getCartTotal(),
    order_total: getOrderTotal(),
    order_items: cart,
  }

  const { mutate } = useMutation(
    tx_reference => placeOrder(order, tx_reference),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEYS.ORDERS)
        toast.success('Order successfully placed')
      },
    }
  )

  return mutate
}
