import React from 'react'
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { MenuIcon, XIcon, LogoutIcon } from '@heroicons/react/outline'
import { UserCircleIcon, ShoppingBagIcon } from '@heroicons/react/solid'
import { EventType } from '@azure/msal-browser'
import { useMsal } from '@azure/msal-react'

import { USER_NAV_ITEMS, USER_MENU_ITEMS, ROUTES } from '../../routes/index'

import { Link } from '@reach/router'

// Hooks
import { useAuth } from '../../hooks/useAuth'
import { useUser } from '../../hooks/useUser'

// Context
import SessionContext from '../../context/SessionContext'
import GraphContext from '../../context/GraphContext'

const NavLink = ({ children, to, ...props }) => (
  <Link
    to={to}
    getProps={({ isCurrent }) => ({
      className: isCurrent ? 'text-secondary-default' : 'text-white',
    })}
    {...props}
  >
    {children}
  </Link>
)

//TODO: close the hamburger menu on item click

export const Navbar = () => {
  const { instance } = useMsal()
  const auth = useAuth()
  const { user, clearUser, clearSession } = useUser()
  const { msalSession } = React.useContext(SessionContext)
  const { graphData } = React.useContext(GraphContext)

  React.useEffect(() => {
    if (msalSession) {
      auth.requestAccessToken()
    }
  }, [msalSession])

  React.useEffect(() => {
    if (graphData && !user) auth.initialiseUser(graphData)
  }, [graphData])

  // TODO: do a check to see if there is an active microsoft session

  // Listen for msal auth state change
  React.useEffect(() => {
    const callbackId = instance.addEventCallback(message => {
      // if (message.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
      //   auth.initialiseUser()
      // }

      if (message.eventType === EventType.LOGOUT_SUCCESS) {
        clearUser()
        clearSession()
      }
    })

    return () => {
      if (callbackId) {
        instance.removeEventCallback(callbackId)
      }
    }
  }, [])

  return (
    <Disclosure as="header" className="bg-primary-default shadow">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto pl-2 sm:px-4 lg:divide-y lg:divide-primary-mid lg:px-8">
            <div className="relative h-16 flex justify-between">
              <div className="relative z-10 px-2 flex lg:px-0">
                <div className="flex-shrink-0 flex items-center">
                  <img
                    className="block h-12 w-auto"
                    src="https://qulmdakymnldvnbspzef.supabase.in/storage/v1/object/public/images/app/logo-hollard.svg"
                    alt="holldard-logo"
                  />
                </div>
              </div>
              {user && (
                <>
                  <div className="relative z-10 flex items-center lg:hidden bg-primary-mid px-4">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="rounded-md p-2 inline-flex items-center justify-center text-white  hover:text-primary-default focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <span className="sr-only">Open menu</span>
                      {open ? (
                        <XIcon className="block h-8 w-8" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-8 w-8"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                  <div className="hidden lg:relative lg:z-10 lg:ml-4 lg:flex lg:items-center">
                    {/* Profile dropdown */}
                    <Menu as="div" className="flex-shrink-0 relative ml-4">
                      <div className="flex space-x-5">
                        <Link
                          to={ROUTES.USER.CART}
                          className="bg-white rounded-full p-1"
                        >
                          <ShoppingBagIcon className="h-8 w-8 text-secondary-default" />
                        </Link>
                        <Menu.Button className="bg-white rounded-full p-1 flex focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                          <span className="sr-only">Open user menu</span>
                          <UserCircleIcon className="h-8 w-8 text-secondary-default" />
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="flex flex-col text-primary-default origin-top-right absolute right-0 mt-2 w-48 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {USER_MENU_ITEMS.map(item => (
                            <Menu.Item key={item.name}>
                              <Link
                                key={item.name}
                                to={item.route}
                                className="hover:bg-primary-light px-4 py-2"
                              >
                                {item.name}
                              </Link>
                            </Menu.Item>
                          ))}
                          <button
                            className="hover:bg-tertiary-default bg-tertiary-light text-tertiary-default hover:text-white px-4 py-2"
                            onClick={() => auth.signOutWithMsal()}
                          >
                            Logout
                          </button>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                </>
              )}
            </div>
            {user && (
              <nav
                className="hidden lg:py-2 lg:flex lg:space-x-8"
                aria-label="Global"
              >
                {USER_NAV_ITEMS.map(item => (
                  <NavLink key={item.name} to={item.route}>
                    {item.name}
                  </NavLink>
                ))}
              </nav>
            )}
          </div>
          {user && (
            <Disclosure.Panel
              as="nav"
              className="lg:hidden"
              aria-label="Global"
            >
              <div className="pt-2 pb-3 px-8 space-y-3 flex flex-col">
                {USER_NAV_ITEMS.map(item => (
                  <Disclosure.Button
                    as={NavLink}
                    key={item.name}
                    to={item.route}
                  >
                    {item.name}
                  </Disclosure.Button>
                ))}
              </div>
              <div className="border-t border-primary-mid pt-4 pb-3">
                <div className="px-4 flex items-center">
                  <div className="flex-shrink-0">
                    <UserCircleIcon className="h-8 w-8 text-primary-light" />
                  </div>
                  <div className="ml-3">
                    <div className="text-base font-bold text-white">
                      {`${user.first_name} ${user.last_name}`}
                    </div>
                    <div className="text-sm font-medium text-primary-light">
                      {user.email_address}
                    </div>
                  </div>
                  <button
                    type="button"
                    className="ml-auto flex-shrink-0 bg-primary-light rounded-full p-3 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    onClick={() => auth.signOutWithMsal()}
                  >
                    <span className="sr-only">Logout</span>
                    <LogoutIcon
                      className="h-6 w-6 text-primary-default"
                      aria-hidden="true"
                    />
                  </button>
                </div>
                <div className="mt-3 px-2 space-y-3 flex flex-col px-8">
                  {USER_MENU_ITEMS.map(item => (
                    <Disclosure.Button
                      as={NavLink}
                      key={item.name}
                      to={item.route}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
              </div>
            </Disclosure.Panel>
          )}
        </>
      )}
    </Disclosure>
  )
}
