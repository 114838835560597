import { useQuery, useQueryClient } from 'react-query'
import { Axios } from '../config/axios.js'
import { QUERY_KEYS } from '../config/constants.js'

const getProducts = async () => {
  const { data } = await Axios({
    method: 'GET',
    url: '/Products',
    params: {
      select: '*',
    },
  })

  return data
}

export const useProducts = () => {
  const fallback = []
  const {
    data = fallback,
    isFetching,
    isLoading,
  } = useQuery(QUERY_KEYS.PRODUCTS, getProducts)

  return {
    data,
    isFetching,
    isLoading,
  }
}

export const usePrefetchProducts = () => {
  const queryClient = useQueryClient()
  queryClient.prefetchQuery(QUERY_KEYS.PRODUCTS, getProducts)
}
