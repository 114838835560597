import React from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'

export const EmptyState = ({ children }) => {
  return (
    <div className="flex flex-col my-32 justify-center items-center space-y-3 w-full text-accent-mid">
      <ExclamationCircleIcon className="block w-20 h-20" />
      {children}
    </div>
  )
}
