import React from 'react'
import { toast } from 'react-toastify'
import { useUser } from './useUser'
import { supabaseClient } from '../config/supabase'
import { navigate } from '@reach/router'

// Msal
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../config/msalConfig'
import { callMsGraph } from '../config/graph'

// Context
import SessionContext from '../context/SessionContext'
import GraphContext from '../context/GraphContext'
import AuthLoadingContext from '../context/AuthLoadingContext'
import UserTypeContext from '../context/UserTypeContext.js'

// Local storage
import { setStoredSession } from '../local-storage/userStorage'
import {
  setStoredGraphData,
  clearGraphData,
} from '../local-storage/graphStorage'

import { ROUTES } from '../routes'
import { USER_TYPES } from '../utils/constants.js'

export const useAuth = () => {
  const { updateUser, clearSession, clearUser } = useUser()
  const { instance, accounts } = useMsal()
  const { setMsalSession } = React.useContext(SessionContext)
  const { setGraphData } = React.useContext(GraphContext)
  const { setLoading } = React.useContext(AuthLoadingContext)
  const { setUserType } = React.useContext(UserTypeContext)

  /**
   * @msalAuthentication
   */
  function requestAccessToken() {
    instance
      .acquireTokenSilent({
        ...loginRequest,
        account: accounts[0],
      })
      .then(res => {
        callMsGraph(res.accessToken).then(res => {
          setGraphData(res)
          setStoredGraphData(res)
          // initialiseUser(res)
        })
      })
  }

  function signInWithMsal() {
    setLoading(true)
    instance
      .loginPopup(loginRequest)
      .then(res => {
        setMsalSession(res)
        setStoredSession(res)
        // requestAccessToken()
      })
      .catch(e => {
        setLoading(false)
        toast.error('Sorry could not sign you in at this time.')
        console.log(e)
      })
  }

  function signOutWithMsal() {
    instance.logoutPopup({
      postLogoutRedirectUri: ROUTES.SHARED.LOGIN,
    })
    clearGraphData()
    toast.success('Successfully signed out')
  }

  async function initialiseUser(graphData) {
    let user
    console.log('I ran')
    // if (getCartUser() !== user.id) {
    //   clearCart()
    //   clearCartVendor()
    //   // clearCartUser()
    //   clearStoredShippingInfo()
    // }

    const { data } = await supabaseClient
      .from('Users')
      .select('*')
      .eq('id', graphData.id)

    if (data.length === 0) {
      const firstName = graphData.givenName
      const lastName = graphData.surname
      await supabaseClient.from('Users').insert([
        {
          id: graphData.id,
          first_name: firstName,
          last_name: lastName,
          email_address: graphData.mail,
        },
      ])
      user = {
        id: graphData.id,
        first_name: firstName,
        last_name: lastName,
        email_address: graphData.mail,
      }
    } else {
      user = data[0]
    }

    setLoading(false)
    setUserType(USER_TYPES.USER)
    updateUser(user)
    navigate(ROUTES.USER.HOME)
    toast.success('Successfully signed in!')
  }

  /**
   * @Vendor Authentication
   */
  const vendorSignIn = async ({ email, password }) => {
    setLoading(true)

    const { user, error } = await supabaseClient.auth.signIn({
      email,
      password,
    })

    console.log(error)

    if (user) {
      setUserType(USER_TYPES.VENDOR)
      updateUser(user)
      setLoading(false)
      navigate(ROUTES.USER.HOME)
      toast.success('Successfully signed in!')
    }

    if (error) {
      setLoading(false)
      toast.error(error.message)
    }
  }

  const vendorSignOut = async () => {
    await supabaseClient.auth.signOut()
    clearSession()
    clearUser()
  }

  return {
    signInWithMsal,
    signOutWithMsal,
    initialiseUser,
    requestAccessToken,
    vendorSignIn,
    vendorSignOut,
  }
}
