import React from 'react'

// Components
import { Navbar } from './Navbar'

export const Layout = ({ children }) => {
  return (
    <React.Fragment>
      <Navbar />
      <main className="h-screen">
        <div className="max-w-7xl mx-auto px-6 py-4 lg:py-8 lg:px-8">
          {children}
        </div>
      </main>
    </React.Fragment>
  )
}
