import React from 'react'
import { useForm } from 'react-hook-form'

// Components
import { FormRadioGroup, FormInput } from '../form'

// Hooks
import {
  useShippingInfo,
  shippingOptions,
} from '../../hooks/useShippingInfo.js'
import { useUser } from '../../hooks/useUser.js'

export const CheckoutShipping = ({ handleNext }) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm()
  const {
    shippingInfo,
    updateShippingInfo,
    updateContactInfo,
    updateShippingOption,
    shippingOption,
    contactInfo,
  } = useShippingInfo()
  const { user } = useUser()

  const handleSaveShippingInfo = values => {
    updateShippingInfo(values)
    handleNext()
  }

  const handleSaveContactInfo = values => {
    updateContactInfo(values)
    handleNext()
  }

  return (
    <div className="space-y-4">
      <div className="w-full lg:w-1/2">
        <h1 className="text-lg text-primary-default">
          Select a delivery option
        </h1>
        <FormRadioGroup
          options={shippingOptions}
          selected={shippingOption}
          setSelected={updateShippingOption}
        />
      </div>

      <div className="w-full lg:w-1/2 text-gray-700 space-y-4">
        {shippingOption.name === 'Collect' ? (
          <div className="space-y-1">
            <h1 className="text-primary-default text-xl">
              Collection Procedure
            </h1>
            <p>
              You will be notified when your order is ready for collection.
              Please see the information below for your pickup.
            </p>

            <div className="flex flex-col">
              <span>
                <strong>Collection Address: </strong> Cnr 4th Street & Chadwick
                Rd, Wynberg, Sandton{' '}
              </span>
              <span>
                <strong>Call Center Number: </strong>
                <a href="tel:+27765701428">076 570 1428</a> (Weekdays 8am - 5pm)
              </span>
              <span>
                <strong>Email: </strong>
                <a href="mailto:support@ukhuni.co.za">support@ukhuni.co.za</a> –
                Ref: Hollard
              </span>
            </div>
          </div>
        ) : null}

        {shippingOption.name === 'Collect' ? (
          <form
            id="collect-form"
            className="space-y-2"
            onSubmit={handleSubmit(handleSaveContactInfo)}
          >
            <h1 className="text-primary-default text-xl">
              Enter contact information
            </h1>
            <FormInput
              id="phoneNumber"
              errors={errors}
              label="Phone Number"
              register={register}
              placeholder="0831234567"
              defaultValue={contactInfo?.phoneNumber || ''}
              required
            />
            <FormInput
              id="email"
              errors={errors}
              label="Email"
              register={register}
              placeholder="johndoe@gmail.com"
              defaultValue={contactInfo?.email || user?.email_address || ''}
              required
            />
          </form>
        ) : null}
        {/* Shipping information form begins */}
        {shippingOption.name === 'Deliver' ? (
          <form
            onSubmit={handleSubmit(handleSaveShippingInfo)}
            className="space-y-2"
            id="deliver-form"
          >
            <h1 className="text-primary-default text-xl">
              Enter delivery information
            </h1>
            <FormInput
              id="addressLine1"
              errors={errors}
              label="Address Line 1"
              register={register}
              defaultValue={shippingInfo?.addressLine1 || ''}
              required
              placeholder="11 Alice Ln, Sandhurst"
            />
            <FormInput
              id="suburb"
              errors={errors}
              label="Suburb"
              register={register}
              placeholder="Sandton"
              defaultValue={shippingInfo?.suburb || ''}
              required
            />
            <FormInput
              id="city"
              errors={errors}
              label="City"
              register={register}
              placeholder="Johannesburg"
              defaultValue={shippingInfo?.city || ''}
              required
            />
            <FormInput
              id="province"
              errors={errors}
              label="Province"
              register={register}
              placeholder="Gauteng"
              defaultValue={shippingInfo?.province || ''}
              required
            />
            <FormInput
              id="country"
              errors={errors}
              label="Country"
              register={register}
              placeholder="South Africa"
              defaultValue={shippingInfo?.country || ''}
              required
            />
            <FormInput
              id="postalCode"
              errors={errors}
              label="Postal Code"
              register={register}
              placeholder="2196"
              defaultValue={shippingInfo?.postalCode || ''}
              required
            />
            <h1 className="text-primary-default text-xl">
              Enter contact information
            </h1>
            <FormInput
              id="phoneNumber"
              errors={errors}
              label="Phone Number"
              register={register}
              placeholder="0831234567"
              defaultValue={
                contactInfo?.phoneNumber || shippingInfo?.phoneNumber || ''
              }
              required
            />
            <FormInput
              id="email"
              errors={errors}
              label="Email"
              register={register}
              placeholder="johndoe@gmail.com"
              defaultValue={shippingInfo?.email || user.email_address || ''}
              required
            />
          </form>
        ) : null}
      </div>
    </div>
  )
}
