import React from 'react'
import { useQuery } from 'react-query'

// Components
import { AppHeading } from '../../components/shared/AppHeading.jsx'
import { AppSubheading } from '../../components/shared/AppSubheading.jsx'
import { CartCard } from '../../components/shared/CartCard.jsx'
import { OrderCartSummary } from '../../components/shared/OrderSummary.jsx'
import { BackButton } from '../../components/shared/BackButton.jsx'

import { Axios } from '../../config/axios.js'
import { QUERY_KEYS } from '../../config/constants.js'

// Utils
import { classNames } from '../../utils/functions.js'

const getOrder = async orderId => {
  const { data } = await Axios({
    method: 'GET',
    url: '/Orders',
    params: {
      select: '*',
      id: `eq.${orderId}`,
    },
  })

  return data[0]
}

const UserOrderView = ({ id }) => {
  const { data: order, isFetching } = useQuery([QUERY_KEYS.ORDER, id], () =>
    getOrder(id)
  )
  return (
    <div>
      <BackButton />
      <AppHeading>Order</AppHeading>
      {isFetching ? (
        <div className="h-24 w-1/3 bg-gray-100 animate-pulse" />
      ) : (
        <AppSubheading>#{order.tx_reference}</AppSubheading>
      )}
      <div className="flex flex-col lg:flex-row space-y-10 lg:space-y-0 lg:space-x-10 my-6">
        {/*   Order Items */}
        <div
          className={classNames(
            isFetching ? 'min-h-screen' : 'max-h-screen',
            'w-full lg:w-5/12 hide-scrollbar bg-white p-5 shadow-sm space-y-3'
          )}
        >
          <h4 className="text-lg text-primary-default">Order Items</h4>
          {/*  cart items */}
          {isFetching ? (
            <div className="space-y-3">
              <div className="h-52 sm:w-full bg-gray-100 animate-pulse" />
              <div className="h-52 sm:w-full bg-gray-100 animate-pulse" />
              <div className="h-52 sm:w-full bg-gray-100 animate-pulse" />
              <div className="h-52 sm:w-full bg-gray-100 animate-pulse" />
            </div>
          ) : (
            <React.Fragment>
              {order.order_items.map(item => (
                <CartCard isOrderHistory item={item} key={item.id} />
              ))}
            </React.Fragment>
          )}
        </div>
        {/*  Order Summary */}
        <div className="w-full lg:w-7/12">
          {isFetching ? (
            <div className="flex flex-col flex-1 gap-5 sm:p-2">
              <div className="flex flex-1 flex-col gap-3">
                <div className="bg-gray-200 w-full animate-pulse h-14 rounded-2xl" />
                <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" />
                <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" />
                <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" />
                <div className="bg-gray-200 w-full animate-pulse h-3 rounded-2xl" />
              </div>
              <div className="mt-auto flex gap-3">
                <div className="bg-gray-200 w-20 h-8 animate-pulse rounded-full" />
                <div className="bg-gray-200 w-20 h-8 animate-pulse rounded-full" />
                <div className="bg-gray-200 w-20 h-8 animate-pulse rounded-full ml-auto" />
              </div>
            </div>
          ) : (
            <OrderCartSummary isOrderHistory order={order} />
          )}
        </div>
      </div>
    </div>
  )
}

export default UserOrderView
