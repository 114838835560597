import React from 'react'
import { ChevronLeftIcon } from '@heroicons/react/outline'
import { navigate } from '@reach/router'
import { Button } from './Button.jsx'

const goBack = () => {
  navigate(-1)
}

export const BackButton = () => {
  return (
    <div className="w-1/4 md:w-1/12 mb-4">
      <Button tertiary onClick={() => goBack()}>
        <div className="flex space-x-1">
          <ChevronLeftIcon className="block h-6 w-6" />
          <span>Back</span>
        </div>
      </Button>
    </div>
  )
}
