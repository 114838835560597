import React from 'react'

const Heading = ({ children }) => (
  <h1 className="text-primary-default text-xl">{children}</h1>
)

const SubHeading = ({ children }) => (
  <h1 className="text-tertiary-default text-lg">{children}</h1>
)

const Paragraph = ({ children }) => <p className="text-gray-700">{children}</p>

export const PopiaCopy = () => {
  return (
    <div className="space-y-6 my-4">
      <div>
        <Heading>Welcome Hollardite and Hollard Partners</Heading>
        <Paragraph>
          We need your personal information to allow you access to the Work From
          Anywhere benefits available on this Digital Concierge. By opting in on
          this page you are giving us the necessary permission to share and keep
          safe with the third party providers we have partnered with on this
          platform, your personal information for the sole purpose of providing
          you with their specific benefit. You may choose not to provide us with
          the information we need, however, this will mean that you will not
          benefit from the wonderful discounts and services available on the
          Digital Concierge Desk.
        </Paragraph>
      </div>

      <div className="space-y-2">
        <Heading>Terms and Conditions</Heading>
        <div>
          <SubHeading>Your Personal Information</SubHeading>
          <Paragraph>
            If you agree to provide us with your personal information, you give
            us permission to use it for the purpose explained above. You also
            confirm that we have obtained below the following information
            directly from you and not from any other data source: Your full name
            and your cellphone number.
          </Paragraph>
        </div>
        <div className="space-y-2">
          <SubHeading>Your Rights To Your Personal Information</SubHeading>
          <Paragraph>
            If you want to access, correct or update your personal information
            that you provided to us, please contact the Digital Concierge
            administrator by clicking on the “contact us” link and we will
            respond to your request as soon as reasonably possible. You also
            have the right to address any complaints regarding the use of your
            personal information to the Information Regulator at
            <a
              href="mailto:inforeg@justice.gov.za"
              className="text-tertiary-default"
            >
              inforeg@justice.gov.za
            </a>{' '}
            or by visiting their website on{' '}
            <a
              href="https://www.justice.gov.za"
              target="_blank"
              rel="noopener noreferrer"
              className="text-tertiary-default"
            >
              www.justice.gov.za
            </a>
            .
          </Paragraph>
        </div>
        <div className="space-y-2">
          <SubHeading>How We Protect Your Personal Information</SubHeading>
          <Paragraph>
            We care about the privacy and security of your personal information
            and we take our responsibility to protect your personal information
            very seriously. The security of your personal information is
            governed by the privacy laws of South Africa, and the privacy laws
            of he European Union in the instance where your personal information
            is stored at a hosting environment located outside of South Africa.
            Your personal information is provided to a third party that manages
            this Digital Concierge system. In terms of our contract with this
            third party we need them to follow all of Hollard’s privacy and
            security requirements.
          </Paragraph>
        </div>
        <div className="space-y-2">
          <SubHeading>
            How Long We Will Keep Your Personal Information
          </SubHeading>
          <Paragraph>
            We will keep your personal information for as long as is necessary
            to allow you access the benefits available on the Digital Concierge,
            to comply with our legal obligations, to resolve disputes and to
            protect our Hollardites and Hollard Partners, assets and operations.
          </Paragraph>
        </div>
      </div>
    </div>
  )
}
